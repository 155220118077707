import { generic_server, private_server } from '../../utils/axios';
import { fileNameByUrl, fileTypeByUrl } from '../../utils/files';


const useDoctor = () => {   

    const actions = {
        onGetDetails: async(iduser) => {
            let result = null
            const request = await private_server.get(`/doctor/${iduser}/details`)
            result = request.data.data.doctor_details
            return result
        },
        onUpdateDetails: async(iduser, data2send) => {
            await private_server.patch(`/doctor/${iduser}/details`, data2send)
        },
        onGetSpecialities: async(iduser) => {
            const request = await private_server.get(`/doctor/${iduser}/specialities`)
            let _specialities = request.data.data.doctor_specialities
            return _specialities.length ? _specialities[0] : null
        },
        onUpdateSpecialities: async(iduser, data2send) => {
            await private_server.post(`/doctor/${iduser}/speciality`, data2send)
        },
        onDeleteSpecialities: async(iduser, speciality_id) => {
            await private_server.delete(`/doctor/${iduser}/speciality`,{params:{speciality_id}})
        },
        onGetDoctorSlots: async(iduser) => {
            let result = []
            const request = await private_server.get(`/doctor/${iduser}/slots`)
            result = request.data.data.doctor_slots
            return result
        },
        onDeleteDoctorSlot: async(iduser, data2send) => {
            console.log('Data2send', data2send)
            await private_server.delete(`/doctor/${iduser}/slot`, {params:data2send})
        },
        onAddDoctorSlots: async(iduser, data2send) => {
            await private_server.post(`/doctor/${iduser}/slots`, data2send)
        },
        onAddDoctorSlot: async(iduser, data2send) => {
            await private_server.post(`/doctor/${iduser}/slot`, data2send)
        }

    }

    return {
        actions
    }
}
 
export default useDoctor;