import { private_server } from '../../utils/axios';
import { CURRENCY } from '../../utils/enums';


const useOrder = () => {   

    const actions = {
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/order/list`, {params:{...params}})
            result.data = request.data.data.orders
            result.count = request.data.data.count
            return result
        },
        onGet: async(orderid) => {
            let result = null
            const request = await private_server.get(`/order/${orderid}`)
            result = request.data.data.order
            return result
        },
        onDeleteOrder: async(orderid) => {
            await private_server.delete(`/order/${orderid}/template`)
        },
        onAddTemplate: async(data2send) => {
            let result = null
            const request = await private_server.post('/order/template/me', {currency_id: CURRENCY.MXN, ...data2send})
            result = request.data.data.order_id
            return result
        },
        onEdit: async(orderid, userid, data2send) => {
            await private_server.patch(`/order/${userid}/${orderid}`, data2send)
        },
        onAssignUser: async(orderid, data2send) => {
            await private_server.post(`order/me/assign/${orderid}/template`, data2send)
        },
        onShip: async(orderid, data2send) => {
            await private_server.post(`order/${orderid}/ship`, data2send)
        },
        onUpdateShip: async(orderid, data2send) => {
            await private_server.post(`order/${orderid}/ship/update`, data2send)
        },
        onSetPendingShipment: async(orderid) => {
            await private_server.post(`order/${orderid}/setpendingshipment`)
        },
        onDeliver: async(orderid) => {
            await private_server.post(`order/${orderid}/deliver`)
        },
        onUndeliver: async(orderid) => {
            await private_server.post(`order/${orderid}/undeliver`)
        },
        
    }

    return {
        actions
    }
}
 
export default useOrder;