import { Card, Grid, Stack, Typography } from '@mui/material';
import React from 'react'
import ContainedButton from '../../../../../../components/actions/ContainedButton';
import InputForm from '../../../../../../components/forms/InputForm';
import InputSelectForm from '../../../../../../components/forms/InputSelectForm';
import SwitchForm from '../../../../../../components/forms/SwitchForm';

const DoctorDetailsCard = ({
    form, 
    onChange,
}) => {
    return ( 
        
            <>
                <Typography variant='subtitle1' sx={{paddingBottom:3}}>Información del doctor</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <InputSelectForm data={form.speciality_id} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputSelectForm data={form.sub_speciality_id} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputForm data={form.license} onChange={onChange} />
                    </Grid>
                    <Grid item xs={0} md={6}></Grid>
                    <Grid item xs={12} md={6}>
                        <SwitchForm data={form.enabled_pharmacy} onChange={onChange} />
                    </Grid>
                    {form.enabled_pharmacy.value === 1 && <Grid item xs={12} md={6}>
                        <InputSelectForm data={form.pharmacy_id} onChange={onChange} />
                    </Grid>}
                    
                </Grid>
                
            </>
     );
}
 
export default DoctorDetailsCard;