import { Box, Grid, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Stack } from '@mui/system';
import React, { useState, useEffect } from 'react';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import InputForm from '../../../components/forms/InputForm';
import BaseModal from '../../../components/modals/BaseModal';
import useForm from '../../../hooks/useForm';
import CeluIcon from '../../../components/platform/CeluIcon';
import cartIcon from '../../../assets/platform/icons/shipping_types/local.svg'
import useCart from '../../../hooks/api/useCart';
import useOrder from '../../../hooks/api/useOrder';
const StyledRoot = styled('div')(({ theme }) => ({
    //right: 0,
    //bottom: 0,
    //zIndex: 9998,
    width: '100%',
    maxWidth:350,
    margin:'auto',
    minHeight: '30vh',
    //position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  }));

const Send2ShipmentModal = (props) => {

    const {open, onClose, onRefresh, onCompleted, mode, order} = props
    const  classes = useStyles()
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const orderManager = useOrder()
    const controller = useForm(form_data)
    const {form, onChangeForm, onResetForm, onGetFormData, onInitForm} = controller


    useEffect(() => {
        if(!open){
            onResetForm()
            setSending(false)
            setError(null)
        }else{
            if(mode === 'edit') onInitForm(order)
        }
    },[open])

    const onSubmit = async() => {


        setSending(true)
        let data2send 
        if(mode === 'edit'){
            data2send = onGetFormData(order)
        }else{
            data2send = onGetFormData()
        }

        try {
            console.log('Send data', data2send)
            if(mode === 'edit'){
                await orderManager.actions.onUpdateShip(order?.id_order, data2send)
            }else{
                await orderManager.actions.onShip(order?.id_order, data2send)
            }
            
            await onRefresh()

        } catch (error) {
            setError(error)
            setSending(false)
            return
        }

        setSending(false)
        onClose()

    }

    return ( 
        <BaseModal maxWidth='xs'
        title=''
        open={open} 
        onClose={onClose} >
            <StyledRoot>
                <Stack alignItems='center' spacing={2} style={{width:'100%'}}>
                    <div>
                        <CeluIcon size='medium' icon={cartIcon}/>
                    </div>
                    <Typography variant='h5' align='center' sx={{maxWidth:300}} >
                        Información del envío
                    </Typography>
                    <Box sx={{marginTop:4, marginBottom:16, width:'100%'}}>
                        <InputForm data={form.shipping_company} onChange={onChangeForm}/>
                    </Box>
                    <Box sx={{marginTop:4, marginBottom:16, width:'100%'}}>
                        <InputForm data={form.tracking_number} onChange={onChangeForm}/>
                    </Box>
                    <Box sx={{marginTop:4, marginBottom:16, width:'100%'}}>
                        <InputForm data={form.estimated_delivery_date} onChange={onChangeForm}/>
                    </Box>
                    <Box sx={{marginTop:4, marginBottom:16, width:'100%'}}>
                        <InputForm data={form.shipping_details} onChange={onChangeForm}/>
                    </Box>
                    {error && 
                        <ErrorAlert 
                        error={error} 
                        onClose={() => setError(null)} />
                    }
                    <ContainedButton loading={sending} onClick={onSubmit}>
                        {mode === 'edit' ? 'Guardar' : 'Continuar'}
                    </ContainedButton>
                </Stack>
            </StyledRoot>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    }
}))
 
export default Send2ShipmentModal;

const form_data = {
    shipping_company: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'shipping_company',
            type: 'text',
            fullWidth: true,
            label:'Empresa',
            placeholder:'DHL, Fedex, 99minutos, etc...',
            helperText:'Debe de contener de 1 a 255 caracteres '
        },
        rules: {type: 'distance', min:1, max:255}
    },
    tracking_number: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'tracking_number',
            type: 'text',
            fullWidth: true,
            label:'Número de seguimiento',
            placeholder:'Número de seguimiento',
            helperText:'Debe de contener de 1 a 255 caracteres '
        },
        rules: {type: 'distance', min:1, max:255}
    },
    estimated_delivery_date: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'estimated_delivery_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha estimada de entrega',
            placeholder:'Fecha estimada de entrega',
            helperText:'Debe de ser una fecha válida'
        },
        rules: {type: 'date'}
    },
    shipping_details: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'shipping_details',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:5,
            label:'Comentarios adicionales',
            placeholder:'Ingrese algún comentario...',
            helperText:'Debe de contener de 1 a 512 caracteres '
        },
        rules: {type: 'distance', min:0, max:512}
    },
}

