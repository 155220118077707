import { alpha, Grid, Icon, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import ContainedButton from '../../actions/ContainedButton';
import ConfirmDialog from '../../confirm-dialog';
import Iconify from '../../iconify';

const HourSlots = ({
    start_hour, end_hour, onDelete
}) => {

    const classes = useStyles()
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openPopover, setOpenPopover] = useState(null);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
      };
    
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const onInnerDelete = () => {
        console.log('Borrar')
        handleCloseConfirm()
        onDelete()
    }

    return ( 
        <div className={classes.root}>
            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Eliminar horario"
                content="¿Está seguro de eliminar este horario? Ya no podrá recuperarse en un futuro"
                action={
                <ContainedButton size='small' onClick={onInnerDelete}>
                    Eliminar
                </ContainedButton>
                }
            />
            <Grid container spacing={1} alignItems='center'>
                <Grid item>
                    <Iconify icon='ic:sharp-access-time' sx={{marginTop:0.5}}/>
                </Grid>
                <Grid item xs>
                    <Typography variant='body2'>{start_hour} - {end_hour} </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleOpenConfirm}><Iconify icon='ic:baseline-delete-outline' /></IconButton>
                </Grid>
            </Grid>
           
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:alpha(grey[100], 1),
        padding:'0px 12px',
        paddingBottom:4,
        borderRadius:8
    }
}))
 
export default HourSlots;