import { useCallback, useEffect, useState } from "react"

const useTableSettings = ({
    initial_filter = {},
    initial_params = {}
}) => {

    const [filter, setFilter] = useState(initial_filter)
    const [pagination, setPagination] = useState(initial_params)

    const onUpdateFilterValue = useCallback((key, value) => {
        console.log('Update filter', key, value)
        let temp = {...filter}
        temp[key].value = value
        setFilter(temp)
        onUpdatePaginationValue('offset', 0)
    }, [filter])

    const onUpdateFilterMultipleValue = useCallback((data) => {
        let temp = {...filter, ...data}
        setFilter(temp)
        onUpdatePaginationValue('offset', 0)
    }, [filter])

    const onUpdatePaginationValue = useCallback((key, value) => {
        let temp = {...pagination}
        temp[key] = value
        setPagination(temp)
    }, [pagination])

    const onUpdatePaginationMultipleValue = useCallback((data) => {
        let temp = {...pagination, ...data}
        setPagination(temp)
    }, [pagination])

    const onResetFilter = useCallback(() => {
        setFilter(initial_filter)
    }, [filter, initial_filter])

    const onResetPagination = useCallback(() => {
        setPagination(initial_params)
    }, [filter, initial_params])

    return {
        filter, 
        pagination,
        onUpdateFilterValue,
        onUpdatePaginationValue,
        onUpdatePaginationMultipleValue,
        onResetFilter,
        onResetPagination,
        setPagination,
        onUpdateFilterMultipleValue, 
        setFilter,
        setPagination
    }

}

export default useTableSettings