import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Stack } from '@mui/system';
import React, { useState, useEffect } from 'react';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import InputForm from '../../../components/forms/InputForm';
import BaseModal from '../../../components/modals/BaseModal';
import useForm from '../../../hooks/useForm';
import useOrder from '../../../hooks/api/useOrder';
import moment from 'moment-timezone'
import useDoctor from '../../../hooks/api/useDoctor';
import { SLOTS_TIME } from '../../../utils/enums';

const weekdays_init = [
    {weekday:0, checked:false, label:'L'},
    {weekday:1, checked:false, label:'M'},
    {weekday:2, checked:false, label:'Mi'},
    {weekday:3, checked:false, label:'J'},
    {weekday:4, checked:false, label:'V'},
    {weekday:5, checked:false, label:'S'},
    {weekday:6, checked:false, label:'D'},
]

const AddDoctorSlots = (props) => {

    const {open, onClose, onRefresh, doctor_id, slot_type } = props
    const doctorManager = useDoctor()
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const controller = useForm(form_data)
    const {form, onChangeForm, onResetForm, onGetFormData, onInitForm} = controller
    const [weekdays, setWeekdays] = useState(JSON.parse(JSON.stringify(weekdays_init)))


    useEffect(() => {
        onResetForm()
        setSending(false)
        setError(null)
        setWeekdays(JSON.parse(JSON.stringify(weekdays_init)))
    },[open])

    const onChange = (data, value) => {
        console.log(data,value)
        let _weekdays = [...weekdays]
        _weekdays[data.weekday].checked = value
        setWeekdays(_weekdays)
    }

    const onSubmit = async() => {

        const valid = controller.onValidateForm()
            
        if(!valid){
            setError({message:'Debe llenar todos los campos solicitados'})
            return
        }

        const data2send = onGetFormData()

        const _startmoment = moment(data2send.start_hour,'HH:mm')
        const _endmoment = moment(data2send.end_hour,'HH:mm')
        const diff = _endmoment.unix() - _startmoment.unix()
        if(diff <= 0){
            setError({message:'La hora de inicio debe ser menor a la hora de fin'})
            return
        }

        const minutesDiff = diff/60
        const slots2Create = minutesDiff / SLOTS_TIME[slot_type]
        const minutes2add = SLOTS_TIME[slot_type]

        console.log('La diferencia es de', diff/60, 'minutes')
        console.log('El slot es de ', SLOTS_TIME[slot_type], 'minutes')
        console.log('Los slots totales son  ', slots2Create)

        let slots2add = []

        for(let i=0; i<weekdays.length; i++){
            const temp = weekdays[i]
            if(temp.checked) {
                let auxdate = _startmoment.clone()
                for(let j=0; j<slots2Create; j++){
                    slots2add.push({
                        weekday:temp.weekday,
                        start_hour: auxdate.format('HH:mm'),
                        end_hour: auxdate.add(minutes2add, 'minutes').format('HH:mm')
                    })
                }
            }
        }
        console.log(slots2add)
        

        if(slots2add.length === 0){
            setError({message:'Debe seleccionar al menos un día de la semana'})
            return
        }

        console.log(slots2add)

        setSending(true)

        try {
            //for(let i=0; i<slots2add.length; i++){
            //    await doctorManager.actions.onAddDoctorSlot(doctor_id, slots2add[i])
            //}
            await doctorManager.actions.onAddDoctorSlots(doctor_id, {slots:slots2add})
            await onRefresh()
        } catch (error) {
            setError(error)
            setSending(false)
            return
        }

        setSending(false)
        onClose()

    }

    return ( 
        <BaseModal maxWidth='xs'
        title='Agregar horario'
        open={open} 
        onClose={onClose} >
            <Stack spacing={3} >
                <Typography>
                    Ingrese la información solicitada para agregar un nuevo horario
                </Typography>
                <Box>
                    <Stack direction='row' justifyContent='space-between'>
                        {weekdays.map(item => {
                            return(
                                <Stack direction='row' key={`weekdayitem-${item?.weekday}`} alignItems='center'>
                                    <Checkbox checked={item?.checked} onChange={(e, value) => onChange(item,value)}/>
                                    <Typography>{item?.label}</Typography>
                                </Stack>
                            )
                        })}
                    </Stack>
                </Box>
                <InputForm data={form.start_hour} onChange={onChangeForm}/>
                <InputForm data={form.end_hour} onChange={onChangeForm}/>
                {error && 
                    <ErrorAlert 
                    error={error} 
                    onClose={() => setError(null)} />
                }
                <ContainedButton loading={sending} onClick={onSubmit} fullWidth>
                    Agregar
                </ContainedButton>
                
            </Stack>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    }
}))
 
export default AddDoctorSlots;

const form_data = {
    start_hour: {
        value:'09:00',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'start_hour',
            type: 'time',
            fullWidth: true,
            label:'Inicio',
            placeholder:'Inicio',
            helperText:'Debe ser un formato valido de '
        },
        rules: {type: 'time'}
    },
    end_hour: {
        value:'18:00',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'end_hour',
            type: 'time',
            fullWidth: true,
            label:'Fin',
            placeholder:'Fin',
            helperText:'Debe seleccionar una hora de fin'
        },
        rules: {type: 'time'}
    },
}

