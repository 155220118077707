import { Card, Grid, Stack, Typography } from '@mui/material';
import React from 'react'
import ContainedButton from '../../../../../../components/actions/ContainedButton';
import InputForm from '../../../../../../components/forms/InputForm';
import InputSelectForm from '../../../../../../components/forms/InputSelectForm';
import SwitchForm from '../../../../../../components/forms/SwitchForm';

const DoctorTelemedicineCard = ({
    telemedicine,
    form, 
    onChange,
}) => {
    return ( 
        
            <>
                <Typography variant='subtitle1' sx={{paddingBottom:3}}>Información de Celuconsulta</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <InputSelectForm data={form.speciality_id} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='caption' color='textSecondary' sx={{fontWeight:500}}>Duración de consulta</Typography>
                        <Typography variant='subtitle1'>{telemedicine?.speciality_slot_type?.replace('minutes', 'minutos')}</Typography>
                    </Grid>
                    
                </Grid>
                
            </>
     );
}
 
export default DoctorTelemedicineCard;