import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/loaders/LoadingScreen';
// components

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../layouts/Auth/SignInLayout/SignInLayout')));

// DASH
export const UsersListPage = Loadable(lazy(() => import('../pages/Users/UserListPage/UserListPage')));
export const BlogsListPage = Loadable(lazy(() => import('../pages/Blogs/BlogsListPage/BlogsListPage')));
export const AddBlogPage = Loadable(lazy(() => import('../pages/Blogs/AddBlogPage/AddBlogPage')));
export const EditBlogPage = Loadable(lazy(() => import('../pages/Blogs/EditBlogPage/EditBlogPage')));
export const PharmacyListPage = Loadable(lazy(() => import('../pages/Pharmacies/PharmacyListPage/PharmacyListPage')));
export const PharmacyAccountPage = Loadable(lazy(() => import('../pages/Pharmacies/PharmacyAccountPage/PharmacyAccountPage')));

export const RoutesListPage = Loadable(lazy(() => import('../pages/Routes/RoutesListPage/RoutesListPage')));
export const RoutePage = Loadable(lazy(() => import('../pages/Routes/RoutePage/RoutePage')));

export const ProductListPage = Loadable(lazy(() => import('../pages/Products/ProductListPage/ProductListPage')));
export const ProductPage = Loadable(lazy(() => import('../pages/Products/ProductPage/ProductPage')));
export const InventoriesPage = Loadable(lazy(() => import('../pages/Inventories/InventoriesPage/InventoriesPage')));


export const BannersListPage = Loadable(lazy(() => import('../pages/Banners/BannersListPage/BannersListPage')));
export const StoreBannerPage = Loadable(lazy(() => import('../pages/Banners/StoreBannerPage/StoreBannerPage')));


export const CatalogsPage = Loadable(lazy(() => import('../pages/Catalogs/CatalogsPage/CatalogsPage')));
export const CategoryPage = Loadable(lazy(() => import('../pages/Catalogs/CategoryPage/CategoryPage')));
export const SubcategoryPage = Loadable(lazy(() => import('../pages/Catalogs/SubcategoryPage/SubcategoryPage')));
export const SegmentPage = Loadable(lazy(() => import('../pages/Catalogs/SegmentPage/SegmentPage')));
export const SubsegmentPage = Loadable(lazy(() => import('../pages/Catalogs/SubsegmentPage/SubsegmentPage')));


