import { alpha, Button, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import MenuPopover from '../../menu-popover';

const StatusChipBtn = ({
    status, options, enableOptions, label, onChange
}) => {

    const classes = useStyles()

    const [openPopover, setOpenPopover] = useState(null);

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleClickItem = (option) => {
        handleClosePopover();
        console.log(option)
        onChange(option)
        //navigate(path);
      };

    if(!status) return null
    return ( 
        <>
            <div>
                
                <Button 
                    onClick={handleOpenPopover}
                    className={classes.button} 
                    style={{background:alpha(status?.color,0.25), color:status?.color}}
                >
                    <Typography className={classes.label}>{label}</Typography>
                    {status?.label}
                </Button>

                {enableOptions && <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ minWidth: 150, p: 0, }}>
                    <Scrollbars autoHeight autoHeightMin={200}>
                        <Stack sx={{ p: 1 }}>
                            {options.map((option) => (
                                <MenuItem key={option.label} onClick={() => handleClickItem(option.value)}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </Stack>
                    </Scrollbars>
                    

                    <Divider sx={{ borderStyle: 'dashed' }} />

                </MenuPopover>}
            </div>
            
        </>
        
     );
}
 

const useStyles = makeStyles(theme => ({
    button:{
        fontSize:'0.750rem',
        fontWeight:500,
        borderRadius:32,
        padding:'4px 8px',
        position:'relative'
    },
    label:{
        position:'absolute',
        top:-10, left:4,
        fontSize:'0.625rem',
        fontWeight:500,
        color:grey[700],
        background:'white',
        borderRadius:4,
        padding:'0px 4px'
    }
}))

export default StatusChipBtn;