import React, { useRef } from 'react'
import { CircularProgress, Grid, Switch, TextField, Typography } from '@mui/material'
import { ruleValidation } from './customFunctions'
import {withStyles} from '@mui/styles'

const CssTextField = withStyles({
    root: {
        //paddingTop:6,
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  })(TextField);

const SwitchForm = props => {

    const {data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown, delayedSearch, onDelayedSearch, loading, inputRootStyles, disabled, onKeyPress} = props
    const {config, value, isValid, isVisited} = data

    const id = data?.config?.id

    const timerRef = useRef()

    const onInnerChange = event => {
        let temp = {...data};
        temp.value = temp.value === 1 ? 0 : 1;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.compareValue);
        temp.isVisited = true;
        onChange(temp);
    } 

    const onRequestSearch = async() => { 

      if(timerRef.current) clearTimeout(timerRef.current) 
      onDelayedSearch(id, value)
        //
        //let temp = {...tableFilter}
        //temp.offset = 0
        //onUpdateTableFilter(temp)            
    }


    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''

    //console.log(loading)


    return(
        <div>
            <Grid container alignItems='center'>
              <Grid item xs>
                <Typography variant='subtitle2'>{config?.label}</Typography>
              </Grid>
              <Grid item>
                  <Switch checked={value === 1} onChange={onInnerChange}/>
              </Grid>
            </Grid>
        </div>
    )
}

export default SwitchForm