import React, { useState, useEffect } from 'react';
import { Card, CardActions, Divider, Grid, Typography } from '@mui/material';
import DoctorDetailsCard from './components/DoctorDetailsCard';
import useDoctorSection from './useDoctorSection';
import { Stack } from '@mui/system';
import ContainedButton from '../../../../../components/actions/ContainedButton';
import DoctorTelemedicineCard from './components/DoctorTelemedicineCard';
import WorkDaysCard from './components/WorkDaysCard';
import AddDoctorSlots from '../../../../../modals/Doctors/AddDoctorSlots/AddDoctorSlots';
import LoadingContainer from '../../../../../components/loaders/LoadingContainer';

const DoctorSection = () => {

    const { system, data, doctorDetailsCtrl, actions, telemedicineCtrl, modalManager } = useDoctorSection()
    
    return ( 
        <div>
            <AddDoctorSlots doctor_id={data.iduser} slot_type={data?.telemedicine?.speciality_slot_type_id}
            open={modalManager.modals.add_slots} 
            onClose={() => modalManager.onChangeModalStatus('add_slots', false)}
            onRefresh={() => actions.onGetDoctorSlots()}/>
            <Card sx={{ p: 3 }}>
                {system?.loading ? <LoadingContainer />
                :   <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <DoctorDetailsCard 
                                    form={doctorDetailsCtrl.form} 
                                    onChange={doctorDetailsCtrl.onChangeForm} />
                            </Grid>
                            <Grid item xs={12}>
                                <DoctorTelemedicineCard
                                    telemedicine={data.telemedicine} 
                                    form={telemedicineCtrl.form} 
                                    onChange={telemedicineCtrl.onChangeForm} />
                            </Grid>
                            <Grid item xs={12}>
                                <WorkDaysCard workdays={data?.workdays}
                                onAddSlots={() => modalManager.onChangeModalStatus('add_slots', true)}
                                onDeleteSlot={actions.onDeleteSlot}
                                />
                            </Grid>

                        </Grid>
                        <Stack alignItems='flex-end' sx={{pt:3}}>
                            <ContainedButton loading={system.sending} onClick={actions.onSubmit}>
                                Guardar
                            </ContainedButton>
                        </Stack>
                    </>
                }
            </Card>
            
        </div>
     );
}
 
export default DoctorSection;