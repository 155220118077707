
import { Navigate, useRoutes } from "react-router"
import AuthGuard from "../auth/AuthGuard"
import GuestGuard from "../auth/GuestGuard"
//import SignInLayout from "../layouts/Auth/SignInLayout/SignInLayout"
import CompactLayout from "../layouts/CompactLayout"
import DashboardLayout from "../layouts/dashboard/DashboardLayout"
import Page404 from "../layouts/Mantainance/Page404/Page404"
import CartListPage from "../pages/Carts/CartList/CartListPage"
import { 
    LoginPage, 
    UsersListPage, 
    BlogsListPage,
    AddBlogPage, 
    EditBlogPage,
    PharmacyListPage,
    PharmacyAccountPage,
    ProductListPage,
    ProductPage,
    CatalogsPage,
    CategoryPage,
    SubcategoryPage,
    SegmentPage,
    SubsegmentPage,
    BannersListPage,
    StoreBannerPage,
    InventoriesPage,
    RoutesListPage,
    RoutePage
} from "./elements"
import {Typography} from '@mui/material'
import UserAccountPage from "../pages/Users/UserAccountPage/UserAccountPage"
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage/ResetPasswordPage"
import OrderListPage from "../pages/Orders/OrderList/OrderList"

const Router = () => {
    // Auth
    return useRoutes([
        // auth
        {
            path:'auth',
            children:[
                {
                    path:'login',
                    element: <GuestGuard>
                        <LoginPage />
                    </GuestGuard> 
                },
                {
                    path:'recover-password',
                    element: <ResetPasswordPage />
                },
            ]
        },
        // Dashboard
        {
            path:'dash',
            element: <AuthGuard>
                <DashboardLayout />
            </AuthGuard> ,
            children:[
                {
                    path:'app', element: <div><Typography variant="h4">TDB</Typography></div>
                },
                {
                    path:'users/profile', element: <UserAccountPage profile />
                },
                {
                    path:'users/:iduser/account', element: <UserAccountPage />
                },
                {
                    path:'users', element: <UsersListPage />
                },
                {
                    path:'carts', element: <CartListPage />
                },
                {
                    path:'orders',  element: <OrderListPage />
                },
                {
                    path:'pharmacies/:idpharmacy', element: <PharmacyAccountPage />
                },
                {
                    path:'pharmacies', element: <PharmacyListPage />
                },
                {
                    path:'blogs/add', element: <AddBlogPage />
                },
                {
                    path:'blogs/:idblog', element: <EditBlogPage />
                },
                {
                    path:'blogs', element: <BlogsListPage />
                },
                {
                    path:'products/:idproduct', element: <ProductPage />
                },
                {
                    path:'products', element: <ProductListPage />
                },
                {
                    path:'routes/:idroute', element: <RoutePage />
                },
                {
                    path:'routes', element: <RoutesListPage />
                },
                {
                    path:'inventories', element: <InventoriesPage />
                },
                {
                    path:'banners/store/:idbanner', element: <StoreBannerPage />
                },
                {
                    path:'banners', element: <BannersListPage />
                },
                {
                    path:'catalogs/subsegment/:idsubsegment', element: <SubsegmentPage />
                },
                {
                    path:'catalogs/segment/:idsegment', element: <SegmentPage />
                },
                {
                    path:'catalogs/subcategory/:idsubcategory', element: <SubcategoryPage />
                },
                {
                    path:'catalogs/category/:idcategory', element: <CategoryPage />
                },
                {
                    path:'catalogs', element: <CatalogsPage />
                },
                
                
            ]
        },
        // Generico
        {
            element: <CompactLayout />,
            children:[
                {
                    path:'404',
                    element: <Page404 />
                },
            ]
        },
        { path: '*', element: <Navigate to="/auth/login" replace /> },
    ])
}

export default Router