import { generic_server, private_server, public_server } from '../../utils/axios';
import { CURRENCY } from '../../utils/enums';
import { fileTypeByUrl } from '../../utils/files';


const useProduct = () => {   

    const actions = {
        onGetAll: async(params) => {
            //console.log('Parametros de busqueda', params)
            let result = {
                data:[], 
                count:0
            }
            let url = `/product/list/currency/${CURRENCY.MXN}`
            const request = await public_server.get(url, {params:params})
            result.data = request.data.data.products
            result.count = request.data.data.count
            return result
        },
        onSearch: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            let url = `/product/search/currency/2`
            if(params) url = `${url}${params}`
            const request = await public_server.get(url)
            result.data = request.data.data.products
            result.count = request.data.data.count
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/product', data2send)
            result = request.data.data.product_id
            return result
        },
        onGet: async(idproduct) => {
            let result = null
            const request = await private_server.get(`/product/${idproduct}`)
            result = request.data.data.product
            return result
        },
        onEdit: async(idproduct, data2send) => {
            await private_server.patch(`/product/${idproduct}`, data2send)
        },
        onGetProductPrices: async(idproduct) => {
            let result = []
            const request = await private_server.get(`/product/${idproduct}/prices`)
            result = request.data.data.product_prices
            return result
        },
        onGetProductTaxes: async(idproduct) => {
            let result = []
            const request = await public_server.get(`/product/${idproduct}/taxes`)
            result = request.data.data.product_taxes
            return result
        },
        onAddTax: async(idproduct, data2send) => {
            await private_server.post(`/product/${idproduct}/tax`, data2send)
        },
        onUpdateTax: async(idproduct, idtax, data2send) => {
            await private_server.patch(`/product/${idproduct}/tax/${idtax}`, data2send)
        },
        onDeleteTax: async(idproduct, idtax) => {
            await private_server.delete(`/product/${idproduct}/tax/${idtax}`)
        },
        onUpdateProductPrice: async(idproduct, idproductprice, data2send) => {
            await private_server.patch(`/product/${idproduct}/price/${idproductprice}`, data2send)
        },

        ///////////////////// CATALOGS ////////////////////
        onGetProductTypes: async() => {
            let result = null
            const request = await private_server.get(`/product/producttypes`)
            result = request.data.data.product_types
            return result
        },
        onGetProductSubtypes: async() => {
            let result = null
            const request = await private_server.get(`/product/productsubtypes`)
            result = request.data.data.product_sub_types
            return result
        },
        onGetTaxTypes: async() => {
            let result = []
            const request = await private_server.get(`/taxline/types`)
            result = request.data.data.tax_types
            return result
        },

        ///////////////////// IMAGES ////////////////////
        onGetProductImages: async(idproduct) => {
            let result = []
            const request = await private_server.get(`/product/${idproduct}/images`)
            result = request.data.data.product_images
            return result
        },
        onAddProductImage: async(idproduct, data2send) => {
            let result = null
            const request = await private_server.post(`/product/${idproduct}/image`, data2send)
            result = request.data.data.product_image_id
            return result
        },
        onUploadProductImage: async(idproduct, idproductimage, document_type, file) => {
            console.log('Mi archivo', file)
            const fType = fileTypeByUrl(file.name)
            const data2send = {type:fType, document: document_type}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/product/${idproduct}/image/${idproductimage}/file`, data2send)
            console.log(response.data.data)
            await generic_server.put(response.data.data.upload_url, file, options);
        },
        onUpdateProductImage: async(idproduct, idproductimage, data2send) => {
            await private_server.patch(`/product/${idproduct}/image/${idproductimage}`, data2send)
        },
        onDeleteProductImage: async(idproduct, idproductimage) => {
            await private_server.delete(`/product/${idproduct}/image/${idproductimage}`)
        },

    }

    return {
        actions
    }
}
 
export default useProduct;