import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';
import useUser from '../hooks/api/useUser';
// utils
import { private_server, public_server } from '../utils/axios';
import { ROLES, USER_TYPES } from '../utils/enums';
//
import { isSoon2ExpireToken, isValidToken, setSession } from './utils';
import { useInterval } from '../utils/general';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'REFRESH') {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userManager = useUser()


  useInterval(() => {
    if(state.isAuthenticated && state.isInitialized) onCheckToken()
  }, 3000)


  const onCheckToken = useCallback(async() => {

    if(document.hidden) return
    const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';
    if(state.user){
      if(accessToken && isValidToken(accessToken)){
        const response = isSoon2ExpireToken(accessToken)
        try {
          if(response) await reauth()
        } catch (error) {
          console.log('Hubo un error', error)
        }
        
      }else{
        console.log('Cerrar sesion')
        logout()
      }
    }
    
  }, [state.user])

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const user_response = await private_server.get('/users/me')
        const {user} = user_response.data.data
        let pharmacy = null
        if(user.main_role_id === ROLES.DOCTOR){
          pharmacy = await userManager.actions.onGetPharmacy()
        }
        if(user.main_role_id === ROLES.PHARMACY_ASSISTANT){
          pharmacy = await userManager.actions.onGetAssistantPharmacy()
        }

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
            pharmacy
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
            pharmacy: null
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
          pharmacy: null
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (data) => {
    const response = await public_server.post('/login', data);

    const { token, user_id, user_type_id} = response.data.data;
    //console.log('USER ID', user_id)
    if(user_type_id !== USER_TYPES.ADMIN){
      throw {
        message:'Usuario sin privilegios de acceso'
      }
    }

    setSession(token);
    const user_response = await private_server.get('/users/me')
    const {user} = user_response.data.data

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      },
    });
    
//
    setSession(token);
    //const user_request = await private_server(`/`)
//
    
  };

  const reauth = async () => {

    const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

    if (accessToken && isValidToken(accessToken)) {
      try {
        const response = await private_server.get('/login/reauthenticate');
        const token = response.data.data.token
        setSession(token);
      } catch (error) {
        logout()
      }
    }

    
  };

  // REGISTER
  const register = async (email, password, firstName, lastName) => {
    const response = await public_server.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  // LOGOUT
  const logout = async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  };

  const refreshUser = async() => {
    const user_response = await private_server.get('/users/me')
    const {user} = user_response.data.data

    dispatch({
      type: 'REFRESH',
      payload: {
        user
      },
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        refreshUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
