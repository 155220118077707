//import { Button } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import NavigationTab from '../../../components/actions/NavigationTab';
import Iconify from '../../../components/iconify';
//import Iconify from '../../../components/iconify';
import PageLayout from '../../../layouts/PageLayout';
import UserRoleModal from '../../../modals/Users/UserRoleModal/UserRoleModal';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { onGetFullImagePath } from '../../../utils/general';
import AccountPasswordSection from './sections/AccountPasswordSection';
import DoctorSection from './sections/DoctorSection/DoctorSection';
import GeneralAccountSection from './sections/GeneralAccountSection';
import useUserAccountPage from './useUserAccountPage';
import DoctorChatSection from './sections/DoctorChatSection/DoctorChatSection';

const UserAccountPage = (props) => {
    
    
    const {profile} = props
    const {system, data, actions, form, passwordForm, modalManager} = useUserAccountPage({profile})

    const {currentTab, file, account, roles, user_roles, isdoctor} = data
    const profileImg =  onGetFullImagePath(account?.image)
    let TABS = [
        {
          value: 'general',
          label: 'General',
          icon: <Iconify icon="ic:round-account-box" />,
          component: (
            <>
                <UserRoleModal 
                open={modalManager.modals.add_role} mode='add' 
                roles={roles} user={account} 
                user_roles={user_roles}
                onClose={() => modalManager.onChangeModalStatus('add_role', false)}
                onRefresh={actions.onRefreshUserRoles}/>
                <GeneralAccountSection 
                    file={file || profileImg} form={form} loading={system.sending} 
                    roles={roles} user_roles={user_roles}
                    onAvatarDrop={actions.onAvatarDrop}
                    onInputChange={actions.onInputChange}
                    onSubmit={actions.onSubmit}
                    onAddRole={() => modalManager.onChangeModalStatus('add_role', true)}
                    onUpdateRole={actions.onUpdateRole}
                />
            </>
          ),
        },
        {
          value: 'change_password',
          label: 'Seguridad',
          icon: <Iconify icon="ic:round-vpn-key" />,
          component: <AccountPasswordSection profile={profile} onReset={actions.onReset}
            form={passwordForm} loading={system.sending} 
            onInputChange={actions.onPasswordInputChange}
            onSubmit={actions.onUpdatePassword}
          />,
        },
    ];

    if(isdoctor){
        TABS.push({
            value: 'doctor',
            label: 'Información del doctor',
            icon: <Iconify icon="maki:doctor" />,
            component: <DoctorSection />,
        },
        {
            value: 'doctor_chat',
            label: 'Chat doctor',
            icon: <Iconify icon="gridicons:chat" />,
            component: <DoctorChatSection />,
        },
        )
    }

    return ( 
        <PageLayout stretch={true}
            title='Cuenta'
            title_seo='Mi cuenta | Celu'
            loading={system.loading}
            links={[
                {name:'Dashboard', href:PATH_DASHBOARD.general.app},
                {name:'Usuarios', href:PATH_DASHBOARD.general.users},
                {name:'Cuenta', },
            ]}
        >
            <div>

                <NavigationTab options={TABS} value={currentTab} onChange={actions.onChangeSection}/>
                {TABS.map(
                (tab) =>
                    tab.value === currentTab && (
                        <Box key={tab.value} sx={{ mt: 5 }}>
                            {tab.component}
                        </Box>
                    )
                )}
            </div>
        </PageLayout>
     );
}
 
export default UserAccountPage;