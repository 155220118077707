import React from 'react';
import SVGIcon from '../images/SVGIcon';
import logo from '../../assets/company/logo.png'
import {Typography, Stack} from '@mui/material'
import Image from '../images/Image';


const Logo = ({
    size,
    enableText, 
    textVariant
}) => {
    return ( 
        <div>
            <Stack direction='row' spacing={1} alignItems='center'>
                <Image src={logo} 
                sx={{
                    width:size ?? 32,
                    height: size ?? 32
                }} />
                {enableText ? <Typography variant={textVariant ?? 'h6'} >Portal administrador</Typography> : null}
            </Stack>
            
        </div>
     );
}
 
export default Logo;