import React, { useCallback, useRef } from 'react'
import { Box, Card, Fade, Grid, IconButton, Typography, Button } from '@mui/material';
import Iconify from '../../iconify';
import { makeStyles } from '@mui/styles';
import cx from 'classnames'
import LoadingContainer from '../../loaders/LoadingContainer';
import GenericAccordion from '../GenericAccordion';
import ProductsContainer from './ProductsContainer';
import DeliveryContainer from './DeliveryContainer';
import PaymentDetails from './PaymentDetails';
import PaymentType from './PaymentType';
import { Stack } from '@mui/system';
import { onGetDateWithTimezone } from '../../../utils/formatTime';
import StatusChipBtn from './StatusChipBtn';
import { catalogs } from '../../../utils/catalogs';
import ActionOrderButton from '../../actions/ActionOrderButton';
import ShippingDetails from './ShippingDetails';

const OrderDetailCard = (props) => {

    const classes = useStyles()
    const {order, onClose,loading, ismodal, 
        onUpdate, onShip, onUpdateShip, onPendingShipment, onDeliver} = props

    const ref = useRef()
    const refFrame = useRef()

    const line_items = order?.cart?.line_items ?? []

    const date = onGetDateWithTimezone(order?.ordered_at,'DD MMM YY, HH:mm')
    const order_status = catalogs.order_statuses.find(el => el.value === order?.order_status_id)
    const delivery_status = catalogs.route_order_statuses.find(el => el.value === order?.route_order_status_id)
    const payment_status = catalogs.payment_statuses.find(el => el.value === order?.payment_status_id)


    return ( 
        <>
            
            <Fade in timeout={750}>
            <div>
            
            
            <Card 
            ref={ref}
            className={cx({
                [classes.card]:true,
                [classes.card_modal]:ismodal
            })}>
                {!ismodal && <Grid container>
                    <Grid item xs>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={1} alignItems='center'>
                                    <Typography variant='h6'>
                                        {`Orden #${order?.id_order}`}
                                    </Typography>
                                    <Typography variant='body2' color='textSecondary'>
                                        {` ${date ?? 'No disponible'}`}
                                    </Typography>
                                    <StatusChipBtn 
                                        label='Orden'
                                        status={order_status} 
                                        options={catalogs.order_statuses}
                                        onChange={(value) => onUpdate({order_status_id:value})} 
                                    />
                                    { order?.order_type_id === 1 && 
                                    <StatusChipBtn 
                                        label='Envío'
                                        status={delivery_status} 
                                        options={catalogs.route_order_statuses}
                                        onChange={(value) => onUpdate({route_order_status_id:value})}
                                    />}
                                    <StatusChipBtn 
                                        label='Pago'
                                        status={payment_status} 
                                        options={catalogs.order_statuses}
                                        onChange={(value) => onUpdate({order_status_id:value})} 
                                    />
                                </Stack>
                                <Box sx={{marginTop:1, marginBottom:2}}>
                                    <ActionOrderButton 
                                        order_status={order?.order_status_id}
                                        shipping_type={order?.shipping_type_id}  
                                        onShip={onShip}
                                        onPendingShipment={onPendingShipment}
                                        onDeliver={onDeliver}
                                    />
                                </Box>
                            </Grid>
                            
                           
                        </Grid>
                        

                    </Grid>
                     <Grid item>
                        <IconButton onClick={onClose}><Iconify icon='eva:close-fill' sx={{
                                width:24,
                                height:24
                            }}/></IconButton>
                    </Grid>
                </Grid>}
                {loading ? <LoadingContainer /> :
                 <Fade in={!loading}>
                    <div>
                        <ShippingDetails order={order} onUpdateShip={onUpdateShip}/>
                        <GenericAccordion title='Resumen de productos' defaultExpanded>
                            <ProductsContainer line_items={line_items}/>
                        </GenericAccordion >
                        <GenericAccordion title='Entrega' defaultExpanded>
                            <DeliveryContainer order={order} />
                        </GenericAccordion >
                        <GenericAccordion title='Detalle de cobro' defaultExpanded>
                            <PaymentDetails order={order} />
                        </GenericAccordion>
                        <GenericAccordion title='Método de pago' defaultExpanded>
                            <PaymentType order={order} />
                        </GenericAccordion>
                    </div>
                </Fade>}
            </Card>
            </div>
        </Fade>
        </>
        
    );
}

const useStyles = makeStyles(theme => ({
    card:{
        maxWidth:'100%',
        boxSizing:'border-box',
        padding:'24px 16px',
        minHeight:600
    },
    card_modal:{
        boxShadow:'none',
        padding:'0px 0px'
    }
}))
 
export default OrderDetailCard;