import { Icon, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Iconify from '../iconify';

const AddButton = (props) => {

    const classes = useStyles()

    return ( 
        <IconButton className={classes.button} {...props}>
            <Iconify icon='eva:plus-fill' sx={{width:'100%', height:'100%', color:'white'}}/>
        </IconButton>
     );
}

const useStyles = makeStyles(theme => ({
    button:{
        background:theme.palette.primary.main,
        color:'white',
        width:24,
        height:24,
        padding:4,
        '&:hover':{
            background:theme.palette.primary.dark,
        }

    }
}))
 
export default AddButton;