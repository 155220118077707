import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import React, { useState, useEffect, useCallback } from 'react';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import InputSelectForm from '../../../components/forms/InputSelectForm';
import SwitchForm from '../../../components/forms/SwitchForm';
import BaseModal from '../../../components/modals/BaseModal';
import useRole from '../../../hooks/api/useRole';
import useUser from '../../../hooks/api/useUser';
import useForm from '../../../hooks/useForm';
import { onGetCatalogs } from '../../../utils/forms';

const UserRoleModal = (props) => {

    const {open, onClose, onRefresh, origin, mode, roles, user_roles, user} = props
    const  classes = useStyles()
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const formManager = useForm(form_data)
    const userManager = useUser()
    const roleManager = useRole()
    const {form, onValidateForm, onGetFormData, onResetForm, onChangeForm, onInitForm} = formManager

    useEffect(() => {
        if(open) onInitModule()
    },[open, onResetForm, roles, user_roles, origin])

    const onInitModule = useCallback(async() => {

        let filteredData = [...roles]
        if(user_roles.length){
            filteredData = roles.filter(item => !user_roles.some(el => el.role_id === item.id_role))
            if(mode === 'edit' && origin){
                filteredData.push({
                    id_role: origin?.role_id,
                    name:origin?.role
                })
            }
        }
        const roles_catalog = onGetCatalogs(filteredData, 'id_role', 'name')
        console.log(roles_catalog)
        if(mode === 'add'){
            onResetForm({
                catalogs:[{key:'role_id', options:roles_catalog}]
            })
            //const filterData = roles
            //const _roles_catalog = onGetCatalogs()
        }else if(mode === 'edit'){
            onInitForm(origin, {
                catalogs:[{key:'role_id', options:roles_catalog}]
            })
        }
        //const _userrq = await userManager.actions.onGetUser(origin?.user_id)
        //setUser(_userrq)
        //onInitForm(_userrq)
    }, [origin, userManager, onInitForm, roles, user_roles])

    const onSubmit = async() => {
        console.log(form)
        const valid = onValidateForm(form)
            
        if(!valid){
            setError({message:'Debe llenar todos los campos solicitados'})
            return
        }

        let data2send = onGetFormData({origin: mode === 'edit' ? origin : undefined, eraseEmptySpaces:mode === 'edit'})
        if(mode === 'add') data2send.user_id = user?.id_user
        console.log(data2send)

        setSending(true)

        try {
            if(mode === 'add') await roleManager.actions.onAdd2User(data2send)
            if(mode === 'edit') await roleManager.actions.onEdit2User(origin?.user_id, origin?.id_user_role, data2send)
            //await userManager.actions.onEdit(origin?.user_id, data2send)
            await onRefresh()
        } catch (error) {
            setError(error)
            setSending(false)
            return
        }

        setSending(false)
        onClose()

    }

    return ( 
        <BaseModal maxWidth='sm'
        title={mode === 'edit' ? 'Editar rol' : 'Agregar rol'}
        open={open} 
        onClose={onClose} >
            <div>
                <Typography variant='body1' className={classes.description}>
                {mode === 'edit' 
                ? 'En esta sección puede modificar los parámetros de este rol' 
                : 'Ingrese la información solicitada para agregar un nuevo rol'}
                </Typography>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputSelectForm data={form.role_id} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={0} md={6} />
                        <Grid item xs={12} md={6}>
                            <SwitchForm data={form.main_role} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {error && 
                                    <Grid item xs={12}>
                                        <ErrorAlert 
                                        error={error} 
                                        onClose={() => setError(null)} />
                                    </Grid> 
                                }
                                <Grid item xs={12}>
                                    <Stack direction='row' justifyContent='flex-end' >
                                        <ContainedButton  loading={sending} onClick={onSubmit}>
                                            {mode === 'edit' ? 'Guardar' : 'Agregar'}
                                        </ContainedButton>
                                    </Stack>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    }
}))
 
export default UserRoleModal;

const form_data = {
    role_id: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'role_id',
            type: 'select',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Rol',
            placeholder:'Seleccione un rol',
            helperText:'Debe seleccionar una opción'
        },
        rules: {type: 'select'}
    },
    main_role: {
        value:0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'main_role',
            type: 'switch',
            fullWidth: true,
            autoComplete:"new-password",
            label:'¿Rol principal?',
            placeholder:'¿Rol principal?',
            helperText:''
        },
        rules: {type: 'none'}
    },

}