import React, {useState} from 'react'
import { Button, Grid, Icon, IconButton, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import cx from 'classnames'
import Iconify from '../iconify';
import { Box } from '@mui/system';


const options = [
    {value:25, label:25},
    {value:50, label:50},
    {value:100, label:100},
    {value:200, label:200},
]

const useStyles = makeStyles(theme => ({
    iconButton:{
        background:'white',
        padding:6,
        borderRadius:9,
        boxShadow:'0px 4px 8px rgba(102, 102, 102, 0.1)',
        color:theme.palette.primary.main,
        '&:disabled':{
            color:'rgba(74, 71, 71, 0.44)',
            boxShadow:'none',
            background:'white',
        }
    },
    page_button:{
        borderRadius:9,
        background:'transparent',
        color:'#8C8C9B',
        //padding:8,
        width:32,
        height:32,
        fontSize:'0.875rem',
        fontWeight:400,
        '&:hover':{
            background:'transparent',
        }

    },
    page_button_selected:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.main,
        }

    },
    button:{
        background:'white',
        borderRadius:9,
        padding:'8px 16px',
        paddingLeft:24,
        boxShadow:'0px 4px 8px rgba(102, 102, 102, 0.1)',
        position:'relative',
        '&:hover':{
            cursor:'pointer'
        }
    },
    popper:{
        marginTop:8,
        padding:'2px 0px',
        borderRadius:12,
        boxSizing:'border-box',
        overflow:'hidden'
    },
    container:{
        maxHeight:250,
        //background:'red',
        overflowX:'hidden',
        overflowY:'auto'
    },
    item:{
        fontFamily: theme.typography.fontFamily,
        padding:"4px 16px",
        borderTop:`1px solid ${grey[200]}`,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    selected:{
        fontWeight:500
    }
}))

const TablePaginationCustom = props => {

    const classes = useStyles()
    const {total, tableFilter,  onChangePagination} = props
    const {limit, offset} = tableFilter
    const [anchorEl, setAnchorEl] = useState(null)

    const [selected, setSelected] = useState(1)

   

    const onInnerChange = (id) =>{
        setAnchorEl(null)
        let temp = {...tableFilter}
        temp.offset = 0
        temp.limit = id
        onChangePagination(temp)

    }

    const onDecrement = () => {
        let temp = {...tableFilter}
        if(offset > 0){
            temp.offset = temp.offset - 1 
            //console.log(temp.offset + 1)
            setSelected(temp.offset +1 )
        }
        
        onChangePagination(temp)
    }

    const onIncrement = () => {
        let temp = {...tableFilter}
        if(offset < Math.ceil(total/limit)-1){
            temp.offset = temp.offset +1 
            //console.log(temp.offset + 1)
            setSelected(temp.offset + 1)
        }
        
        onChangePagination(temp)
    }

    const onGoToSpecificPage = (new_offset) => {
        let temp = {...tableFilter}
        temp.offset = new_offset
        onChangePagination(temp)
        setSelected(new_offset + 1)
    }

    let pages = []
    for (let i = 0; i < Math.ceil(total/limit); i++) {
        pages.push(i+1)
    }


    let pagesContent = <Grid container spacing={1}>
                            {pages.map((item, ind) => {
                                return (
                                    <Grid item key={`item-page-${ind}`}><IconButton
                                        onClick={()=>onGoToSpecificPage(ind)}
                                        className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === item-1 })}
                                        size="large">{item}</IconButton></Grid>
                                );
                            })}
                        </Grid>

    if(pages.length <= 5){
        
        pagesContent = <Grid container spacing={1}>
                            {pages.map((item, ind) => {
                                return (
                                    <Grid item key={`item-page-${ind}`}><IconButton
                                        onClick={()=>onGoToSpecificPage(ind)}
                                        className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === item-1 })}
                                        size="large">{item}</IconButton></Grid>
                                );
                            })}
                        </Grid>
    }

    //case a
    if (pages.length > 5 && selected < 5 ){
        //console.log ('case a')
       
        pagesContent = <Grid container spacing={1}>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(0)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[0]-1 })}
                                    size="large">
                                    {pages[0]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(1)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[1]-1 })}
                                    size="large">
                                    {pages[1]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(2)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[2]-1 })}
                                    size="large">
                                    {pages[2]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(3)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[3]-1 })}
                                    size="large">
                                    {pages[3]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(4)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[4]-1 })}
                                    size="large">
                                    {pages[4]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography style={{color:'#8C8C9B', margin:'6px 16px 0px'}}>...</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(pages.length - 1 )}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[pages.length-1]-1 })}
                                    size="large">
                                    {pages[pages.length-1]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton className={classes.iconButton} size='small' onClick={onIncrement} disabled={offset >= Math.ceil(total/limit)-1}><Iconify icon='akar-icons:chevron-down'/></IconButton>
                            </Grid>
                        </Grid>
    }

    //case b
    if (pages.length >= 9 && selected < pages.length -3 && selected >=5 ){
        //console.log ('case b')
        
        pagesContent = <Grid container spacing={1}>
                            <Grid item>
                                <IconButton className={classes.iconButton} size='small' onClick={onDecrement} disabled={offset <= 0} ><Iconify icon='akar-icons:chevron-left'/></IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(0)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[0]-1 })}
                                    size="large">
                                    {pages[0]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography style={{color:'#8C8C9B', margin:'6px 16px 0px'}}>...</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(selected-3)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[selected-3]-1 })}
                                    size="large">
                                    {pages[selected -3]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(selected-2)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[selected-2]-1 })}
                                    size="large">
                                    {pages[selected -2]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(selected-1)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[selected-1]-1 })}
                                    size="large">
                                    {pages[selected -1]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(selected)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[selected]-1 })}
                                    size="large">
                                    {pages[selected]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(selected+1)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[selected+1]-1 })}
                                    size="large">
                                    {pages[selected +1]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography style={{color:'#8C8C9B', margin:'6px 16px 0px'}}>...</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(pages.length - 1 )}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[pages.length-1]-1 })}
                                    size="large">
                                    {pages[pages.length-1]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton className={classes.iconButton} size='small' onClick={onIncrement} disabled={offset >= Math.ceil(total/limit)-1}><Iconify icon='akar-icons:chevron-right' /></IconButton>
                            </Grid>
                        </Grid>
    }

    //case c
    if (selected >= pages.length - 3 && pages.length > 5  ){
        //console.log('case c')
        
        pagesContent = <Grid container spacing={1}> 
                            <Grid item>
                                <IconButton className={classes.iconButton} size='small' onClick={onDecrement} disabled={offset <= 0} ><Iconify icon='akar-icons:chevron-left'/></IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(0)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[0]-1 })}
                                    size="large">
                                    {pages[0]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography style={{color:'#8C8C9B', margin:'6px 16px 0px'}}>...</Typography>
                            </Grid> 
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(pages.length-5)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[pages.length-5]-1 })}
                                    size="large">
                                    {pages[pages.length-5]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(pages.length-4)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[pages.length-4]-1 })}
                                    size="large">
                                    {pages[pages.length-4]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(pages.length-3)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[pages.length-3]-1 })}
                                    size="large">
                                    {pages[pages.length-3]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(pages.length-2)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[pages.length-2]-1 })}
                                    size="large">
                                    {pages[pages.length-2]}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={()=>onGoToSpecificPage(pages.length-1)}
                                    className={cx({[classes.page_button]:true, [classes.page_button_selected]: offset === pages[pages.length-1]-1 })}
                                    size="large">
                                    {pages[pages.length-1]}
                                </IconButton>
                            </Grid>                                  
                        </Grid>
    }


    return (
        <div>
            <Grid container spacing={2} alignItems='center' justifyContent='flex-end'>
                
                <Grid item>
                    <div>
                        <Popover classes={{paper:classes.popper}} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={(e) => setAnchorEl(null)} anchorOrigin={{vertical: 'bottom',horizontal: 'left'}} transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                            <div className={classes.container} >
                                {options.map((item,key)=>{
                                    return(
                                        <div key={key.toString()} className={cx({
                                            [classes.item]:true,
                                            [classes.selected]: limit === item.value
                                        })} onClick={() => onInnerChange(item.value)}>
                                            {item.label}
                                        </div>
                                    )
                                })}
                            </div>
                        </Popover>
                        <div className={classes.button} onClick={(event) => setAnchorEl(event.currentTarget)}>
                            <Box
                                sx={{position:'absolute', top:-12, left:12}}
                            > 
                                <Typography variant='caption' color='textSecondary'>Filas</Typography>
                            </Box>
                            <Grid container spacing={1} alignItems='center'>
                                
                                <Grid item>
                                    <Typography variant='body1'>{limit}</Typography>
                                </Grid>
                                <Grid item>
                                  <Iconify icon='akar-icons:chevron-down' width={18}/>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                    {pagesContent}
                </Grid>
            </Grid>
        </div>
    );
}

export default TablePaginationCustom