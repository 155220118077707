import { Grid, Typography, useTheme } from "@mui/material"
import { grey } from "@mui/material/colors"
import { onGetCurrencyValue } from "../../../utils/currencies"
import { makeStyles } from '@mui/styles';



const PaymentDetails = ({order}) =>{

    const theme = useTheme()
    const classes = useStyles()
    //console.log('ORDER',order)
    //console.log('impuestos', order?.payment_taxes)
    const tax_visible = parseFloat(order?.payment_taxes) > 0
    const discount_visible = parseFloat(order?.code_discount) > 0
    const shipping_visible = parseFloat(order?.shipping_cost) > 0

    const subtotal_visible = parseFloat(order?.payment_subtotal) !== parseFloat(order?.payment_total)

    return(
        <Grid container spacing={1}>
            {subtotal_visible ? <Grid item xs={12}>
                <DataInfo label='Subtotal:' data={onGetCurrencyValue(order?.payment_subtotal)} variant='body2'/>
            </Grid> : null}
            {tax_visible ? <Grid item xs={12}>
                <DataInfo label='Impuestos' data={`${onGetCurrencyValue(order?.payment_taxes)}`} variant='body2'/>
            </Grid> : null}
            {shipping_visible ? <Grid item xs={12}>
                <DataInfo label='Costo de envío' data={onGetCurrencyValue(order?.shipping_cost)} variant='body2'/>
            </Grid> : null}
            {discount_visible ? <Grid item xs={12}>
                <DataInfo label='Descuento' data={`-${onGetCurrencyValue(order?.code_discount)}`} color={theme.palette.accent.main}   variant='body2'/>
            </Grid> : null}
            {subtotal_visible ? <Grid item xs={12}>
                <div className={classes.divider}/>
            </Grid> : null}
            <Grid item xs={12}>
                <DataInfo label='Total' data={onGetCurrencyValue(order?.payment_total)} variant='subtitle1'/>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    divider:{
        background:grey[300],
        height:1,
        margin:'8px 0px'
    }
}))

export default PaymentDetails

const DataInfo = ({label, data, bold, variant, color}) => {
    return(
        <Grid container wrap='nowrap'>
            <Grid item xs>
                <Typography variant={variant} color='textSecondary' style={{color:color}}>{label}</Typography>
            </Grid>
            <Grid item >
                <Typography  variant={variant} style={{color:color}}>{data}</Typography>
            </Grid>
        </Grid>
    )
}