import { useCallback, useEffect, useState } from "react"
import useForm from "../../../../../hooks/useForm"
import useDoctor from "../../../../../hooks/api/useDoctor"
import { useParams } from "react-router"
import { useSnackbar } from "notistack"

const useDoctorChatSection = () => {

    const {iduser} = useParams()
    const doctorManager = useDoctor()
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [chat_available, setChatAvailable] = useState(false)
    const [doctor, setDoctor] = useState()

    const formManager = useForm(form_data)

    const onGetDoctorInfo = useCallback(async() => {
        const _doctor = await doctorManager.actions.onGetDetails(iduser)
        console.log(_doctor)
        setDoctor(_doctor)
        setChatAvailable(_doctor?.private_chat === 1)
        formManager.onInitForm(_doctor)
    }, [iduser, formManager])

    const onChangeChatStatus = useCallback(async() => {
        try {
            await doctorManager.actions.onUpdateDetails(iduser, {private_chat: chat_available ? 0 : 1 })
            await onGetDoctorInfo()
            enqueueSnackbar('Chat habilitado exitósamente', {variant:"success"})
        } catch (error) {
            console.log(error)
            enqueueSnackbar(error?.message, {variant:"error"})
        }

        
    }, [])

    const initialize = useCallback(async()=>{
        setLoading(true)
        try {
            await onGetDoctorInfo()
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        initialize()
    }, [])

    return{
        loading, 
        sending,
        error,
        chat_available,
        onChangeChatStatus,
        formManager
    }
}

export default useDoctorChatSection

const form_data = {
    private_chat_reference_code: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'private_chat_reference_code',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Código de referencia',
            placeholder:'Código de referencia',
            helperText:'Debe ser un valor entre 1 a 10 caracteres'
        },
        rules: {type: 'distance', min:1, max:10}
    },
    license: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'license',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Cédula profesional',
            placeholder:'Cédula profesional',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'distance', min:1, max:255}
    },
}