import { Box, Card, Grid, Stack, Switch, Typography } from '@mui/material';
import React from 'react'
import LoadingContainer from '../../../../../components/loaders/LoadingContainer';
import useDoctorChatSection from './useDoctorChatSection';
import SwitchForm from '../../../../../components/forms/SwitchForm';
import InputForm from '../../../../../components/forms/InputForm';

const DoctorChatSection = () => {

    const {
        loading, 
        sending,
        error,
        chat_available,
        onChangeChatStatus,
        formManager
    } = useDoctorChatSection()

    const {form, onChangeForm} = formManager

    return ( 
        <Box sx={{minHeight:'80vh'}}>
            <LoadingContainer loading={loading}>
                <Card sx={{ p: 3 }}>
                    <Stack direction='row'>
                        <Typography variant='subtitle1' sx={{flex:1}}>Chat doctor</Typography>
                        <Box>
                            <Stack direction='row' alignItems='center'>
                                <Typography variant='subtitle2'>¿Habilitar chat?</Typography>
                                <Switch size='small' checked={chat_available} onChange={onChangeChatStatus}/>
                            </Stack>
                        </Box>
                    </Stack>
                    <Box sx={{mt:3}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.private_chat_reference_code} onChange={onChangeForm}  />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.license} onChange={onChangeForm}  />
                            </Grid>
                        </Grid>
                    </Box>

                </Card>
            </LoadingContainer>
        </Box>
        
     );
}
 
export default DoctorChatSection;