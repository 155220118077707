import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ORDER_STATUS } from '../../../utils/enums';
import { onGetDateWithTimezone } from '../../../utils/formatTime';
import TextButton from '../../actions/TextButton';

const ShippingDetails = ({
    order, onUpdateShip
}) => {

    if(order?.order_status_id < ORDER_STATUS.SHIPPED){
        return null
    }

    const date = onGetDateWithTimezone(order?.estimated_delivery_date, 'DD MMM YY')
    

    return ( 
        <>
            <Grid container wrap='nowrap'>
                <Grid item xs>
                    <Typography variant='subtitle2'>Información de envío</Typography>
                </Grid>
                {order?.order_status_id < ORDER_STATUS.DELIVERED && <Grid item>
                    <TextButton size='small' onClick={onUpdateShip}>modificar</TextButton>
                </Grid>}
            </Grid>
            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack direction='row' spacing={1}>
                            <Typography variant='body2' color='textSecondary'>Empresa</Typography>
                            <Typography variant='subtitle2'>{order?.shipping_company ?? '-'}</Typography>
                        </Stack>
                        <Stack direction='row' spacing={1}>
                            <Typography variant='body2' color='textSecondary'>No. de seguimiento</Typography>
                            <Typography variant='subtitle2'>{order?.tracking_number ?? '-'}</Typography>
                        </Stack>
                        <Stack direction='row' spacing={1}>
                            <Typography variant='body2' color='textSecondary'>Fecha de entrega</Typography>
                            <Typography variant='subtitle2'>{date ?? '-'}</Typography>
                        </Stack>
                        <Stack direction='row' spacing={1}>
                            <Typography variant='body2' color='textSecondary'>Comentarios</Typography>
                            <Typography variant='subtitle2'>{order?.shipping_details ?? '-'}</Typography>
                        </Stack>
                    </Grid>                
                </Grid>
            </Box>
        </>
     );
}
 
export default ShippingDetails;