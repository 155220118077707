import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { ORDER_STATUS, SHIPPING_TYPES } from '../../utils/enums';

const ActionOrderButton = ({
    order_status,
    shipping_type,
    onShip,
    onPendingShipment,
    onDeliver
}) => {

    let nextButton = null
    let cancelButton = null
    let pendingShipmentBtn = null

    if(order_status === ORDER_STATUS.PENDING_SHIPMENT && 
        (shipping_type === SHIPPING_TYPES.LOCAL || shipping_type === SHIPPING_TYPES.NATIONAL) ){
        nextButton = (
            <Button variant='outlined' size='small' onClick={onShip}>
                Enviar pedido
            </Button>
        )
    }

    if(order_status === ORDER_STATUS.SHIPPED){
        nextButton = (
            <Button variant='outlined' size='small' color='success' onClick={onDeliver}>
                Entregado
            </Button>
        )
    }

    if(order_status === ORDER_STATUS.SHIPPED){
        pendingShipmentBtn = (
            <Button variant='outlined' size='small' color='warning' onClick={onPendingShipment}>
                Pendiente de envío
            </Button>
        )
    }

    

    /*if(order_status === ORDER_STATUS.PENDING_SHIPMENT || order_status === ORDER_STATUS.PICKUP_PENDING){
        cancelButton = (
            <Button variant='outlined' size='small' color='error'>
                Cancelar
            </Button>
        )
    }*/

    return ( 
        <>
            <Stack direction='row' spacing={1}>
                {pendingShipmentBtn}
                {nextButton}
                {cancelButton}
            </Stack>
            
        </>
     );
}
 
export default ActionOrderButton;