// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dash';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/recover-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    users: path(ROOTS_DASHBOARD, '/users'),
    carts: path(ROOTS_DASHBOARD, '/carts'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    products: path(ROOTS_DASHBOARD, '/products'),
    inventories: path(ROOTS_DASHBOARD, '/inventories'),
    blogs: path(ROOTS_DASHBOARD, '/blogs'),
    pharmacies: path(ROOTS_DASHBOARD, '/pharmacies'),
    catalogs: path(ROOTS_DASHBOARD, '/catalogs'),
    banners: path(ROOTS_DASHBOARD, '/banners'),
    routes: path(ROOTS_DASHBOARD, '/routes'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/users'),
    account: (userid) => path(ROOTS_DASHBOARD, `/users/${userid}/account`),
    profile: path(ROOTS_DASHBOARD, '/users/profile'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blogs: {
    root: path(ROOTS_DASHBOARD, '/blogs'),
    add: path(ROOTS_DASHBOARD, '/blogs/add'),
    view: (idblog) => path(ROOTS_DASHBOARD, `/blogs/${idblog}`),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    //view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  pharmacies: {
    root: path(ROOTS_DASHBOARD, '/pharmacies'),
    view: (idpharmacy) => path(ROOTS_DASHBOARD, `/pharmacies/${idpharmacy}`),
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/products'),
    view: (idproduct) => path(ROOTS_DASHBOARD, `/products/${idproduct}`),
  },
  routes: {
    root: path(ROOTS_DASHBOARD, '/routes'),
    view: (idroute) => path(ROOTS_DASHBOARD, `/routes/${idroute}`),
  },
  inventories: {
    root: path(ROOTS_DASHBOARD, '/inventories'),
  },
  banners: {
    root: path(ROOTS_DASHBOARD, '/banners'),
    store: (idbanner) => path(ROOTS_DASHBOARD, `/banners/store/${idbanner}`),
    promotional: (idbanner) => path(ROOTS_DASHBOARD, `/banners/promotional/${idbanner}`),
    telemedicine: (idbanner) => path(ROOTS_DASHBOARD, `/banners/telemedicine/${idbanner}`),
  },
  catalogs: {
    root: path(ROOTS_DASHBOARD, '/catalogs'),
    category: (id) => path(ROOTS_DASHBOARD, `/catalogs/category/${id}`),
    subcategory: (id) => path(ROOTS_DASHBOARD, `/catalogs/subcategory/${id}`),
    segment: (id) => path(ROOTS_DASHBOARD, `/catalogs/segment/${id}`),
    subsegment: (id) => path(ROOTS_DASHBOARD, `/catalogs/subsegment/${id}`),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1';
