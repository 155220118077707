import { Accordion, AccordionDetails, AccordionSummary, Grid, Icon, Typography } from "@mui/material"
import { makeStyles } from '@mui/styles';
import Iconify from "../iconify";

const GenericAccordion = (props) => {

    const classes = useStyles()
    const {title, color, defaultExpanded, startAddornment,endAddornment} = props

    return(
        <Accordion className={classes.accordion} classes={{root:classes.root_accordion}} defaultExpanded={defaultExpanded} style={{boxShadow:'none'}}>
            <AccordionSummary 
            expandIcon={<Iconify icon='material-symbols:keyboard-arrow-down'/>}
            id="panel1a-header"
            className={classes.accordion_summary}
            classes={{root:classes.accordion_summary_root}}
            >
                <Grid container alignItems='center'>
                    <Grid item>
                        {startAddornment}
                    </Grid>
                    <Grid item xs>
                        <Typography className={classes.heading} variant='subtitle2' >{title ?? ''}</Typography>
                    </Grid>
                    <Grid item>
                        {endAddornment}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails >
                <div >
                    {props.children}
                </div>
            </AccordionDetails>
        </Accordion>
    )

    
}

const useStyles = makeStyles(theme => ({
    heading:{
        //fontSize:18,
        //fontWeight:600,
        //background:'red'
    },
    root_accordion:{
        boxShadow:'none',     
        '&:before':{
            //top: "-1px",
            //left: 0,
            //right: 0,
            //height: "1px",
            //content: "",
            //opacity: "1",
            //position: "absolute",
            //transition: "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            //backgroundColor:theme.palette.primary.light, 
        }
        
    },
    accordion_summary_root:{
        boxShadow:'none',
        //background:theme.palette.primary.light,
    },
    accordion:{
        boxShadow:'none',
        //borderColor:'red',
        //border:'none'
    },
    accordion_summary:{
        boxShadow:'none',
    }
}))

export default GenericAccordion

