import { private_server } from '../../utils/axios';


const useCatalog = () => {   

    const actions = {
        onGetZipInfo: async(zip) => {
            let result = null
            if(!zip || zip.length < 3) return null
            const request = await private_server.get(`/address/zipinfo?zip=${zip}`)
            result = request.data.data.zip_information
            return result
        },
        onGetSpecialities: async() => {
            let result = []
            const request = await private_server.get(`/speciality`)
            result = request.data.data.specialities
            return result
        },
        onGetUser: async(id) => {
            let result = null
            const request = await private_server.get(`/pharmacy/doctor/me/user/${id}`)
            result = request.data.data.pharmacy_user
            return result
        },
        onGetPharmacy: async() => {
            let result = null
            const request = await private_server.get(`/pharmacy/doctor/me`)
            result = request.data.data.pharmacy_data
            return result
        },
        onGetAssistantPharmacy: async() => {
            let result = null
            const userpharmacy_rq = await private_server.get('/pharmacyuser/list/me')
            const pharmacy_user = userpharmacy_rq.data.data.pharmacy_users[0]
            const request = await private_server.get(`/pharmacy/${pharmacy_user?.pharmacy_id}`)
            result = request.data.data.pharmacy_data
            return result
        },
        onAddUser: async(data2send) => {
            let result = null
            const request = await private_server.post('/pharmacy/doctor/me/user', data2send)
            result = request.data.data
            return result
        },
        onEditUser: async(id, data2send) => {
            await private_server.patch(`/pharmacy/doctor/me/user/${id}`, data2send)
        },
    }

    return {
        actions
    }
}
 
export default useCatalog;