import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server } from '../../utils/axios';
import { fileTypeByUrl } from '../../utils/files';
import { onGetFullImagePath } from '../../utils/general';


const usePharmacy = () => {   

    const actions = {
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/pharmacy/list`, {params:params})
            result.data = request.data.data.pharmacies_data
            result.count = request.data.data.count
            return result
        },
        onGet: async(idpharmacy) => {
            let result = null
            const request = await private_server.get(`/pharmacy/${idpharmacy}`)
            result = request.data.data.pharmacy_data
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post(`/pharmacy`, data2send)
            result = request.data.data.pharmacy_id
            return result
        },
        onEdit: async(idpharmacy, data2send) => {
            await private_server.patch(`/pharmacy/${idpharmacy}`, data2send)
        },
        onDelete: async(idpharmacy) => {
            await private_server.delete(`/pharmacy/${idpharmacy}`)
        },
        onGetBlobFile: async(relativepath, name) => {
            let _url = onGetFullImagePath(relativepath)
            if(!_url) return null
            const config = { responseType: 'blob' };
            const response = await generic_server.get(_url, config);
            return new File([response.data], name??'image-tionix', { type: response.data.type })
            
        },
        onUpdateFile: async(id, file, documentType) => {
            const fType = fileTypeByUrl(file.name)
            const data2send = {type:fType, document: documentType}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/pharmacy/${id}/image`, data2send)
            await generic_server.put(response.data.data.upload_url, file, options);
        },
        ///////////////  DOCTOR PHARMACY ROUTES /////////////////
        onGetTemplateCountTotal: async(pharmacyid, params) => {
            let request = await private_server.get(`/pharmacy/${pharmacyid}/templatecounttotal`, {params:params})
            return request.data.data.template_count
        },
        onGetCartCountTotal: async(pharmacyid, params) => {
            let request = await private_server.get(`/pharmacy/${pharmacyid}/cartcounttotal`, {params:params})
            return request.data.data.cart_count
        },
        onGetCartCount: async(pharmacyid, params) => {
            let request = await private_server.get(`/pharmacy/${pharmacyid}/cartcount`, {params:params})
            return request.data.data.carta ?? []
        },
        onGetSales: async(pharmacyid, params) => {
            let request = await private_server.get(`/pharmacy/${pharmacyid}/sales`, {params:params})
            return request.data.data.sales ?? []
        },
        onGetBestProductsQuantity: async(pharmacyid, params) => {
            let request = await private_server.get(`/pharmacy/${pharmacyid}/bestproducts/quantity`, {params:params})
            return request.data.data.products ?? []
        },
        onGetMothlySalesQuantity: async(pharmacyid, params) => {
            let request = await private_server.get(`/pharmacy/${pharmacyid}/salesbymonth`, {params:params})
            return request.data.data.monthly_sales ?? []
        },
        /////////////////////   USERS   ///////////////////////////////////
        onGetAllUsers: async(pharmacyid, params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/pharmacy/${pharmacyid}/users`, params)
            result.data = request.data.data.pharmacy_users
            result.count = request.data.data.count
            return result
        },
        onGetUser: async(pharmacyid, id) => {
            let result = null
            const request = await private_server.get(`/pharmacy/${pharmacyid}/user/${id}`)
            result = request.data.data.pharmacy_user
            return result
        },
        onDeleteUser: async(pharmacyid, id) => {
             await private_server.delete(`/pharmacy/${pharmacyid}/user/${id}`)
        },
        onEditUser: async(pharmacyid, userid, data2send) => {
            console.log(pharmacyid, userid, data2send)
            await private_server.patch(`/pharmacy/${pharmacyid}/user/${userid}`, data2send)
       },
        onAddUser: async(pharmacyid, data2send) => {
            let result = null
            const request = await private_server.post(`/pharmacy/${pharmacyid}/user`, data2send)
            result = request.data.data
            console.log('Add pharmacy user data', result)
            return result
        },
        ////////////////// ORDERS ///////////////////
        onGetOrders: async(pharmacyid, params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/pharmacy/${pharmacyid}/orders`, {params:params})
            result.data = request.data.data.orders
            result.count = request.data.data.count
            return result
        },
    }

    return {
        actions
    }
}
 
export default usePharmacy;