import { generic_server, private_server } from '../../utils/axios';
import { fileNameByUrl, fileTypeByUrl } from '../../utils/files';


const useRole = () => {   

    const actions = {
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/role/list', {params:params})
            result.data = request.data.data.roles
            result.count = request.data.data.count
            return result
        },
        onGetAllByUser: async(userid, params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/userrole/list/${userid}`, {params:params})
            result.data = request.data.data.user_roles
            result.count = request.data.data.count
            return result
        },
        onGet: async(id) => {
            let result = null
            const request = await private_server.get(`/role/${id}`)
            result = request.data.data.role
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/role', data2send)
            result = request.data.data.role_id
            return result
        },
        onAdd2User: async(data2send) => {
            let result = null
            const request = await private_server.post('/userrole', data2send)
            result = request.data.data.user_role_id
            return result
        },
        onEdit: async(id, data2send) => {
            await private_server.patch(`/role/${id}`, data2send)
        },
        onEdit2User: async(userid, userroleid, data2send) => {
            await private_server.patch(`/userrole/${userid}/${userroleid}`, data2send)
        },
        onDelete: async(id) => {
            await private_server.delete(`/role/${id}`)
        },
        onUpdatePicture: async(id, file) => {
            console.log('Mi archivo', file)
            const fType = fileTypeByUrl(file.name)
            const data2send = {type:fType}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/users/${id}/image`, data2send)
            console.log(response.data.data)
            await generic_server.put(response.data.data.upload_url, file, options);
        },

    }

    return {
        actions
    }
}
 
export default useRole;