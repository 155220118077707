import { endOfMonth, startOfDay, startOfMonth, subMonths, subYears } from "date-fns";
import moment from "moment-timezone";
import { useCallback, useEffect, useRef, useState } from "react"
import { useAuthContext } from "../../../auth/useAuthContext"
import { useDateRangePicker } from "../../../components/date-range-picker";
import { useSnackbar } from '../../../components/snackbar';
import useCart from "../../../hooks/api/useCart";
import useOrder from "../../../hooks/api/useOrder";
import useTableSettings from "../../../hooks/useTableSettings";
import { ORDER_STATUS } from "../../../utils/enums";
import { onGetDateWithTimezone } from "../../../utils/formatTime";
import { sleep } from "../../../utils/general";
import axios from 'axios'

const useOrderListPage = () => {

    const { pharmacy} = useAuthContext()
    const { enqueueSnackbar } = useSnackbar();

    const today = new Date()


    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [order_loading, setOrderLoading] = useState(false)
    const [error, setError] = useState(false)
    const [order, setOrder] = useState()
    const [orders, setOrders] = useState([])
    const [count, setCount] = useState(0)
    const [view_order, setViewOrder] = useState(false)
    const [order_type, setOrderType] = useState('pending')

    const [modals, setModals] = useState({
        shipment_modal:false,
        update_shipment_modal:false,
        delete_user:false,
        add_product_order:false,
        assign_user_order:false,
        date_selector:false
    })

    const orderManager = useOrder()
    const cartManager = useCart()

    const dateRangeManager = useDateRangePicker(subMonths(startOfMonth(today),3),endOfMonth(today))

    const tableController = useTableSettings({
        initial_filter:{
            search:{key:'id_order', value:'', operator:'LIKE'},
            //start_date:{key: 'created_at' , value:subYears(startOfMonth(today),2), operator:'>='},
            //end_date:{key:'created_at', value:endOfMonth(today), operator:'<='}
        },
        initial_params:{
            order_by:'id_order',
            order:1,
            limit:25, 
            offset:0,
            //start_date:startOfMonth(today),
            //end_date:endOfMonth(today)
        }
    })

    const docRef = useRef()

    let cancelToken 

    useEffect(() => {
        //console.log(pharmacy)
        actions.onInitModule()
    }, [])

    useEffect(() => {
        actions.onRefreshData()
    }, [tableController.pagination, tableController.filter, order_type, 
        dateRangeManager.startDate, dateRangeManager.endDate
    ])

    const onGetParams = useCallback(() => {

        const _startdate = onGetDateWithTimezone(dateRangeManager.startDate.toISOString()).tz(moment.tz.guess()).toISOString()
        const _enddate = onGetDateWithTimezone(dateRangeManager.endDate.toISOString()).tz(moment.tz.guess()).toISOString()

        

        let result = {...tableController.pagination}
        result.offset = tableController.pagination.offset*tableController.pagination.limit

        if(order_type === 'pending') result.order = 0

        let ANDargs = [
            {"field":"ordered_at","operator":">=","value":_startdate},
            {"field":"ordered_at","operator":"<=","value":_enddate}
        ]
        Object.keys(tableController.filter).forEach(key => {
            const item = tableController.filter[key]
            if(item.value){
                if(item.operator === 'LIKE'){
                    ANDargs.push({field:item.key, operator:item.operator, value:`%${item.value}%`})
                }else{
                    ANDargs.push({field:item.key, operator:item.operator, value:item.value})
                }
            }
        })
        
        if(order_type){
            //console.log('OrderType', order_type)
            let ORFilter = []
            if(order_type === 'pending'){
                ORFilter.push({"field":"order_status_id","operator":"<","value":ORDER_STATUS.DELIVERED})
                ORFilter.push({"field":"order_status_id","operator":"=","value":ORDER_STATUS.PICKUP_PENDING})
                ANDargs.push({OR:ORFilter})
            }
            if(order_type === 'delivered'){
                ORFilter.push({"field":"order_status_id","operator":"=","value":ORDER_STATUS.DELIVERED})
                ANDargs.push({OR:ORFilter})
            }
            if(order_type === 'cancel'){
                //ANDargs.push({OR:ORFilter})
                ANDargs.push({"field":"order_status_id","operator":">","value":ORDER_STATUS.DELIVERED})
                ANDargs.push({"field":"order_status_id","operator":"<","value":ORDER_STATUS.PICKUP_PENDING})
            }
            //console.log(order_type, ORFilter)
            
        }

        if(ANDargs.length){
            const temp = `${JSON.stringify({"AND":ANDargs})}`
            //console.log(JSON.parse(temp))
            result.filter = temp
        }
        
        return result
    },[tableController.pagination, tableController.filter, order_type,dateRangeManager.startDate, dateRangeManager.endDate])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const params = onGetParams()
                console.log(params)

                //Check if there are any previous pending requests
                if (typeof cancelToken != typeof undefined) cancelToken.cancel("Operation canceled due to new request.")
                cancelToken = axios.CancelToken.source()

                const _orders = await orderManager.actions.onGetAll(params)
                console.log(_orders)
                setOrders(_orders.data)
                setCount(_orders.count)
            } catch (error) {
                console.log(error)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onRefreshData: async() => {
            setSending(true)
            try {
                const params = onGetParams()

                if (typeof cancelToken != typeof undefined) cancelToken.cancel("Operation canceled due to new request.")
                cancelToken = axios.CancelToken.source()

                const _orders = await orderManager.actions.onGetAll(params)
                setOrders(_orders.data)
                setCount(_orders.count)
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onRefreshSelectedOrder: async() => {
            setSending(true)
            try {
                const params = onGetParams()
                const _orders = await orderManager.actions.onGetAll(params)
                setOrders(_orders.data)
                setCount(_orders.count)
                if(order){
                    const _order = await orderManager.actions.onGet(order?.id_order)
                    setOrder(_order)
                }
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onSelectRow: async(row) => {
            setOrderLoading(true)
            setOrder(row)
            setViewOrder(true)
            try {
                const _order = await orderManager.actions.onGet(row?.id_order)
                setOrder(_order)
                //const _cart = await cartManager.actions.on
                
            } catch (error) {
                console.log(error)
            }
            setOrderLoading(false)
        },
        onUpdateItem: async(data2send) => {
            console.log(order?.id_order, data2send)
            setOrderLoading(true)
            try {
                await orderManager.actions.onEdit(order?.id_order, order?.user_id, data2send)
                const _order = await orderManager.actions.onGet(order?.id_order)
                setOrder(_order)
            } catch (error) {
                console.log(error)
            }
            setOrderLoading(false)
        },
        onEditRow: async(row) => {
            setOrderLoading(true)
            setOrder(row)
            try {
                const _order = await orderManager.actions.onGetCart(row?.id_order)
                setOrder(_order)
                actions.onChangeModalStatus('edit_order', true)
            } catch (error) {
                console.log(error)
            }
            setOrderLoading(false)
        },
        onDeleteRow: async(row) => {
            console.log('Emlimianr', row)
            setLoading(true)
            try {
                actions.onChangeModalStatus('delete_user', false)
                await orderManager.actions.onDeleteCart(row.id_order)
                await actions.onRefreshData()
                enqueueSnackbar('Carrito eliminado exitosamente', {variant:"success"})
            } catch (error) {
                console.log(error)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onRequestSearch: (value) => {
            console.log(value)
            tableController.onUpdateFilterValue('search', value)
        },
        onCloseCartDetails: () => {
            setViewOrder(false)
            setOrder(null)
            docRef.current = undefined
        },
        onChangePagination: (_data) =>{
            tableController.setPagination(_data)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        }, 
        onChangeSection: (val) => {
            //console.log(val)
            setOrderType(val)
        },
        onChange2PendingShipment: async() => {
            setSending(true)
            try {
                //enqueueSnackbar('Enviando...', {variant:"info"})
                await orderManager.actions.onSetPendingShipment(order?.id_order)
                await actions.onRefreshSelectedOrder()
                enqueueSnackbar('Actualizado correctamente', {variant:"success"})
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onDeliver: async() => {
            setSending(true)
            try {
                //enqueueSnackbar('Enviando...', {variant:"info"})
                await orderManager.actions.onDeliver(order?.id_order)
                setOrderType('delivered')
                await sleep(500)
                //await actions.onRefreshSelectedOrder()
                enqueueSnackbar('Actualizado correctamente', {variant:"success"})
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onChangeStartDate: (value) => {
            tableController.onUpdatePaginationValue('offset', 0)
            dateRangeManager.onChangeStartDate(value)
        },
        onChangeEndDate: (value) => {
            tableController.onUpdatePaginationValue('offset', 0)
            dateRangeManager.onChangeEndDate(value)
        }
    }


    const table_config = tableController.pagination

    const system = {loading, sending, error, order_loading}
    const data = {table_config, count, orders, order, view_order, order_type}

    return {
        system, data, actions, modals, tableController, dateRangeManager, docRef
    }
}
 
export default useOrderListPage;