export function fileData(file) {
    // Url
    if (typeof file === 'string') {
      return {
        key: file,
        preview: file,
        name: fileNameByUrl(file),
        type: fileTypeByUrl(file),
      };
    }
  
    // File
    return {
      key: file.preview,
      name: file.name,
      size: file.size,
      path: file.path,
      type: file.type,
      preview: file.preview,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
    };
}


export function fileTypeByUrl(fileUrl = '') {
    if(fileUrl && fileUrl.split('.').length === 1) return null
    return (fileUrl && fileUrl.split('.').pop()) || '';
  }
  
  // ----------------------------------------------------------------------
  
  export function fileNameByUrl(fileUrl) {
    return fileUrl.split('/').pop();
  }

export const isValidDocument = (filename) => Boolean(filename && filename !== "0" && filename !== 0 && filename !== "")
