import { Box, Button, Card, Grid, Table, TableBody, TableContainer, Typography } from '@mui/material';
import React from 'react';
import Iconify from '../../../../components/iconify';
import TableEmptyRows from '../../../../components/table/TableEmptyRows';
import TableHeadCustom from '../../../../components/table/TableHeadCustom';
import TableNoData from '../../../../components/table/TableNoData';
import RoleTableRow from './RoleTableRow';

const TABLE_HEAD = [
    { id: 'id', label: 'ID', align: 'left' },
    { id: 'role', label: 'Role', align: 'left' },
    { id: 'main_role', label: 'Principal', align: 'left' },
    { id: '', label: '', align: '' },
];

const RolesCard = ({
    roles = [],
    onAdd,
    onSelectRow,
    onDeleteRow,
    onEditRow,
    onUpdateRow

}) => {

    const table_config = {
        order:1,
        orderBy:'id_location'
    }

    const dense = true
    const isNotFound = !roles.length

    return ( 
        <Box>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Typography variant='subtitle1'>Roles</Typography>
                </Grid>
                <Grid item>
                    <Button 
                        variant='contained'  size='medium'
                        onClick={onAdd} 
                        startIcon={<Iconify icon='eva:plus-fill' sx={{width:18, height:18}}/>}
                    >
                        Agregar 
                    </Button>
                </Grid>
            </Grid>
            <Card sx={{overflow:'auto', marginTop:2}}>
                <TableContainer sx={{position: 'relative', overflow: 'unset'  }}>
                    
                        <Table stickyHeader size={'small'} sx={{ minWidth: 200}}>
                            <TableHeadCustom
                                order={table_config.order}
                                orderBy={table_config.order_by}
                                headLabel={TABLE_HEAD}
                                rowCount={roles.length}
                                numSelected={0}
                            />

                            <TableBody>
                            {roles.map((row) => (
                                <RoleTableRow
                                    key={`location-item-${row?.id_user}`}
                                    row={row}
                                    //selected={selected.includes(row.id)}
                                    onSelectRow={() => onSelectRow(row)}
                                    onDeleteRow={() => onDeleteRow(row)}
                                    onEditRow={() => onEditRow(row)}
                                    onUpdateRow={onUpdateRow}
                                />
                            ))}

                            <TableNoData isNotFound={isNotFound} />
                            </TableBody>
                        </Table>
                </TableContainer>

                
            </Card>
        </Box>
     );
}
 
export default RolesCard;