import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import useCatalog from "../../../../../hooks/api/useCatalog"
import useDoctor from "../../../../../hooks/api/useDoctor"
import usePharmacy from "../../../../../hooks/api/usePharmacy"
import useForm from "../../../../../hooks/useForm"
import useModal from "../../../../../hooks/useModal"
import { onGetCatalogs } from "../../../../../utils/forms"
import { onCleanDoctorSlots } from "../../../../../utils/platform"

const useDoctorSection = () => {


    const {iduser} = useParams()
    const { enqueueSnackbar } = useSnackbar();

    const doctorManager = useDoctor()
    const catalogManager = useCatalog()
    const pharmacyManager = usePharmacy()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)

    const [doctor_details, setDoctorDetails] = useState()
    const [speciality, setSpeciality] = useState()
    const [workdays, setWorkdays] = useState([
        {weekday:0, slots:[]}, //Monday
        {weekday:1, slots:[]},
        {weekday:2, slots:[]},
        {weekday:3, slots:[]},
        {weekday:4, slots:[]},
        {weekday:5, slots:[]},
        {weekday:6, slots:[]},//Sunday
    ])

    const [telemedicine, setTelemedicine] = useState()

    const [specialities, setSpecialities] = useState([])
    const [subspecialities, setSubspecialities] = useState([])
    const [pharmacies, setPharmacies] = useState([])

    const doctorDetailsCtrl = useForm(doctor_formdata)
    const telemedicineCtrl = useForm(telemedicine_form)

    const modalManager = useModal({
        add_slots:false,
        edit_slot:false
    })

    


    useEffect(() => {
        actions.onInitModule()
    },[])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const _doctor_details = await doctorManager.actions.onGetDetails(iduser)
                const _speciality = await doctorManager.actions.onGetSpecialities(iduser)
                setDoctorDetails(_doctor_details)
                setSpeciality(_speciality)
                const _specialities = await catalogManager.actions.onGetSpecialities()
                const specialityCat = onGetCatalogs(_specialities, 'id_speciality', 'name')
                
                const _pharmacies = await pharmacyManager.actions.onGetAll({order:0, order_by:'name'})
                setPharmacies(_pharmacies.data)
                setSpecialities(_specialities)
                const pharmacyCat = onGetCatalogs(_pharmacies.data, 'id_pharmacy', 'name')
                doctorDetailsCtrl.onInitForm(_doctor_details, {catalogs:[
                    {key:'speciality_id', options:specialityCat},
                    {key:'sub_speciality_id', options:specialityCat},
                    {key:'pharmacy_id', options:pharmacyCat}
                ]})

                const _doctor_telemedicine = await doctorManager.actions.onGetSpecialities(iduser)
                //console.log(_doctor_telemedicine)
                setTelemedicine(_doctor_telemedicine)
                telemedicineCtrl.onInitForm(_doctor_telemedicine, {catalogs:[
                    {key:'speciality_id', options:specialityCat},
                ]})
                await actions.onGetDoctorSlots()

            }catch(error){
                console.log(error)
            }
            setLoading(false)
        },
        onRefreshData: async() => {
            const _doctor_details = await doctorManager.actions.onGetDetails(iduser)
            const _speciality = await doctorManager.actions.onGetSpecialities(iduser)
            setDoctorDetails(_doctor_details)
            setSpeciality(_speciality)
            const specialityCat = onGetCatalogs(specialities, 'id_speciality', 'name')
            const pharmacyCat = onGetCatalogs(pharmacies, 'id_pharmacy', 'name')
            doctorDetailsCtrl.onInitForm(_doctor_details, {catalogs:[
                {key:'speciality_id', options:specialityCat},
                {key:'sub_speciality_id', options:specialityCat},
                {key:'pharmacy_id', options:pharmacyCat}
            ]})

        },
        onRefreshTelemedicineData: async() => {
            const _doctor_telemedicine = await doctorManager.actions.onGetSpecialities(iduser)
            setTelemedicine(_doctor_telemedicine)
            const specialityCat = onGetCatalogs(specialities, 'id_speciality', 'name')
            telemedicineCtrl.onInitForm(_doctor_telemedicine, {catalogs:[
                {key:'speciality_id', options:specialityCat},
            ]})

        },
        onGetDoctorSlots: async() => {
            try {
                let _slots = await doctorManager.actions.onGetDoctorSlots(iduser)
                let filteredSlots = onCleanDoctorSlots(_slots)
                setWorkdays(filteredSlots)
            } catch (error) {
                
            }
        },
        onDeleteSlot: async(item) => {
            const data2send = {
                weekday:item.weekday,
                start_hour:item.start_hour,
                end_hour:item.end_hour
            }
            console.log('Borrar',data2send)
            try {
                await doctorManager.actions.onDeleteDoctorSlot(iduser, data2send) 
                await actions.onGetDoctorSlots()
                
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
                setSending(false)
                return
            }
            setSending(false)
            enqueueSnackbar("Horario eliminado exitosamente", {variant:"success"})

        },
        onSubmit: async() => {

            const valid = doctorDetailsCtrl.onValidateForm()
            
            if(!valid){
                setError({message:'Debe llenar todos los campos solicitados'})
                return
            }

            const validTelemedicine = telemedicineCtrl.onValidateForm()
            
            if(!validTelemedicine){
                setError({message:'Debe llenar todos los campos solicitados'})
                return
            }
    
            let data2send = doctorDetailsCtrl.onGetFormData({origin: doctor_details, eraseEmptySpaces:true})
            console.log('Submit', data2send)

            let data2sendTelemedicine = telemedicineCtrl.onGetFormData({origin: telemedicine, eraseEmptySpaces:true})
            setSending(true)
            console.log('Submit telemedicine', data2sendTelemedicine)

            try {
                if(JSON.stringify(data2send) !== "{}"){
                    await doctorManager.actions.onUpdateDetails(iduser, data2send)
                    await actions.onRefreshData()
                }
                if(JSON.stringify(data2sendTelemedicine) !== "{}"){
                    if(telemedicine) await doctorManager.actions.onDeleteSpecialities(iduser, telemedicine?.speciality_id)
                    await doctorManager.actions.onUpdateSpecialities(iduser, data2sendTelemedicine)
                }
                
                await actions.onRefreshTelemedicineData()
                
            } catch (error) {
                console.log(error)
                if(error.details) doctorDetailsCtrl.onUpdateDBErrors(error.details)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
                setSending(false)
            }

            setSending(false)
            enqueueSnackbar("Información actualizada exitosamente", {variant:"success"})
        }
    }

    const system = {loading, sending, error}
    const data = {doctor_details, speciality, telemedicine, workdays, iduser}

    return {
        system, 
        data,
        actions,
        doctorDetailsCtrl,
        telemedicineCtrl,
        modalManager
    }

}

export default useDoctorSection

const doctor_formdata = {
    speciality_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'speciality_id',
            type: 'select',
            fullWidth: true,
            label:'Especialidad',
            autoComplete:"new-password",
            placeholder:'Especialidad',
            helperText:'Debe seleccionar al menos una opción'
        },
        rules: {type: 'select'}
    },
    sub_speciality_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'sub_speciality_id',
            type: 'select',
            fullWidth: true,
            label:'Sub-especialidad',
            autoComplete:"new-password",
            placeholder:'Sub-especialidad',
            helperText:'Debe seleccionar al menos una opción'
        },
        rules: {type: 'select'}
    },
    license: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'license',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Cédula profesional',
            placeholder:'Cédula profesional',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'distance', min:1, max:255}
    },
    enabled_pharmacy: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'enabled_pharmacy',
            type: 'switch',
            fullWidth: true,
            autoComplete:"new-password",
            label:'¿Tiene farmacia?',
            placeholder:'¿Tiene farmacia?',
            helperText:''
        },
        rules: {type: 'none'}
    },
    pharmacy_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'pharmacy_id',
            type: 'select',
            fullWidth: true,
            label:'Farmacia',
            autoComplete:"new-password",
            placeholder:'Farmacia',
            helperText:'Debe seleccionar al menos una opción'
        },
        rules: {type: 'select'}
    },
}

const telemedicine_form = {
    speciality_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'speciality_id',
            type: 'select',
            fullWidth: true,
            label:'Especialidad para Celuconsultas',
            autoComplete:"new-password",
            placeholder:'Especialidad para Celuconsultas',
            helperText:'Debe seleccionar al menos una opción'
        },
        rules: {type: 'select'}
    },
}
