import { useEffect, useRef } from "react";
import { CONFIG_SETTINGS } from "../config";
import { isValidDocument } from "./files";

export const getRatio = (ratio = '1/1') => {
    return {
      '4/3': 'calc(100% / 4 * 3)',
      '3/4': 'calc(100% / 3 * 4)',
      '6/4': 'calc(100% / 6 * 4)',
      '4/6': 'calc(100% / 4 * 6)',
      '16/9': 'calc(100% / 16 * 9)',
      '9/16': 'calc(100% / 9 * 16)',
      '21/9': 'calc(100% / 21 * 9)',
      '9/21': 'calc(100% / 9 * 21)',
      '1/1': '100%',
    }[ratio];
}

export const onGetFullImagePath = (src) => {
  const valid = isValidDocument(src)
  if(!valid) return null
  return `${CONFIG_SETTINGS.S3_SERVER_URL}${src}`
}

export const onGetFullname = (user) => {

  const first_name = user?.first_name
  const fathers_last_name = user?.fathers_last_name
  const mothers_last_name = user?.mothers_last_name

  let result =  `${first_name ? first_name : ''}`
  if(fathers_last_name) result+= ` ${fathers_last_name}`
  if(mothers_last_name) result+= ` ${mothers_last_name}`
  //if(!first_name) return 'N/D'
  return result
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const string2Slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn------";

  for (var i=0, l=from.length ; i<l ; i++)
  {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace('.', '-') // replace a dot by a dash 
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
    .replace(/-+/g, '-') // collapse dashes
    .replace( /\//g, '' ); // collapse all forward-slashes

  return str;
}

export const s2ab = (s) =>{ 
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;    
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}



