import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Stack, Button, TableRow, MenuItem, TableCell, IconButton, Typography, Checkbox } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import ConfirmDialog from '../../../../components/confirm-dialog';
import ContainedButton from '../../../../components/actions/ContainedButton';
import Label from '../../../../components/label/Label';

// ----------------------------------------------------------------------

LocationTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function LocationTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, onUpdateRow }) {

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const onInnerChange = (e, value) => {
    const data2send = {main_role: value ? 1 : 0}
    onUpdateRow(row, data2send)
  }

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{cursor:'pointer'}} padding="checkbox" onClick={onSelectRow}>
            <Typography variant="subtitle2" noWrap>
              {row?.id_user_role}
            </Typography>
        </TableCell>

        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
            {row?.role}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{cursor:'pointer'}} padding="checkbox" onClick={onSelectRow}>
            <Checkbox checked={row?.main_role === 1} onChange={onInnerChange}/>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top" sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar role"
        content="¿Está seguro de eliminar este rol? Ya no podrá recuperarse en un futuro"
        action={
          <ContainedButton size='small' onClick={onDeleteRow}>
            Eliminar
          </ContainedButton>
        }
      />
    </>
  );
}
