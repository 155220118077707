import { Typography, Stack, Grid, Box } from '@mui/material';
import React from 'react'
import AddButton from '../../../../../../components/actions/AddButton';
import GenericAccordion from '../../../../../../components/platform/GenericAccordion';
import HourSlots from '../../../../../../components/platform/schedules/HourSlots';
import { DAYS_LABELS } from '../../../../../../utils/enums';

const WorkDaysCard = ({
    workdays=[],
    onDeleteSlot,
    onAddSlots
}) => {
    return (  
        <>
            <Stack direction='row'  spacing={1}>
                <Typography variant='subtitle1' sx={{paddingBottom:3}}>Horarios (Disponibilidad)</Typography>
                <AddButton onClick={onAddSlots} />
            </Stack>
            <div>
                {workdays.length > 0  && workdays.map(item => {
                    return(
                        <GenericAccordion title={DAYS_LABELS[item.weekday+1]} key={`workday-accordion-${item.weekday}`} defaultExpanded >
                            <Grid container spacing={2}>
                                {item.slots.map(el => {
                                    return <Grid item xs={12} md={4} key={`work-slots-${el.id_slot}`}>
                                        <HourSlots 
                                            onDelete={() => onDeleteSlot(el)}
                                            start_hour={el.start_hour} 
                                            end_hour={el.end_hour}
                                        />
                                    </Grid>
                                })}
                                
                                
                            </Grid>
                            {item.slots.length === 0 && (
                                <Box>
                                    <Stack alignItems={'center'}>
                                        <Typography variant='body2'>
                                            No existen horarios registrados
                                        </Typography>
                                    </Stack>
                                    
                                </Box>
                            )}
                        </GenericAccordion >
                    )
                })}
            </div>
        </>
    );
}
 
export default WorkDaysCard;