import React, { useRef } from 'react';
import { 
    Card,
    Table,
    Button,
    TableBody,
    TableContainer,
    Grid,
    Box,
    Hidden,
    LinearProgress,
} from '@mui/material';

import PageLayout from '../../../layouts/PageLayout';
import useOrderListPage from './useOrderListPage';
import AddCartModal from '../../../modals/Carts/AddCartModal/AddCartModal';
import { makeStyles } from '@mui/styles';
import CartDetails from './components/CartDetails';
import AddProduct2CartModal from '../../../modals/Carts/AddProduct2CartModal/AddProduct2CartModal';
import TablePaginationCustom from '../../../components/table/TablePaginationCustom';
import EditCartModal from '../../../modals/Carts/EditCartModal/EditCartModal';
import BaseModal from '../../../components/modals/BaseModal';
import TableActionBar from '../../../components/table/TableActionBar';
import AssignUser2CartModal from '../../../modals/Carts/AssignUser2CartModal/AssignUser2CartModal';
import OrderCard from '../../../components/platform/orders/OrderCard';
import { Stack } from '@mui/system';
import OrderDetailCard from '../../../components/platform/orders/OrderDetailCard';
import NavigationTab from '../../../components/actions/NavigationTab';
import Iconify from '../../../components/iconify';
import Scrollbars from 'react-custom-scrollbars';
import { grey } from '@mui/material/colors';
import Send2ShipmentModal from '../../../modals/Orders/ShipmentModals/Send2ShipmentModal';
import DateRangePicker from '../../../components/date-range-picker';
import FileFilterButton from '../../../components/actions/FileFilterButton';
import ReactToPrint from 'react-to-print';
const TABLE_HEAD = [
    { id: 'id_order', label: 'ID', align: 'left' },
    { id: 'alias', label: 'Etiqueta', align: 'left' },
    { id: 'seller', label: 'Creado por', align: 'left' },
    { id: 'creation_date', label: 'Fecha', align: 'left' },
    //{ id: 'payment_subtotal', label: 'Total', align: 'left' },
    { id: '', label: '', align: '' },
  ];
  

const OrderListPage = () => {

    const classes = useStyles()
    const {actions, data, system, modals, tableController, dateRangeManager, docRef} = useOrderListPage()
    const {table_config, count, orders, view_order, order, order_type} = data
    
    const stackRef = useRef()


    const page = table_config.offset
    const rowsPerPage = table_config.limit

    const isNotFound = !orders.length

    const TABS = [
        {
          value: 'pending',
          label: 'Pendientes',
          icon: <Iconify icon="material-symbols:pending-actions" />,
        },
        {
          value: 'delivered',
          label: 'Completadas',
          icon: <Iconify icon="fluent-mdl2:completed" />,
        },
        {
            value: 'cancel',
            label: 'Canceladas',
            icon: <Iconify icon="material-symbols:cancel-outline-rounded" />,
          },
    ];

    return ( 
        <PageLayout
            title='Órdenes'
            title_seo='Órdernes | Celu'
            loading={system.loading}
        >
            <div >
                <Hidden lgUp>
                    <CartModal 
                        open={view_order} 
                        order={order}
                        onClose={actions.onCloseCartDetails} 
                        loading={system?.order_loading}
                        onRefresh={actions.onRefreshData}
                        onAddProduct={() => actions.onChangeModalStatus('add_product_order', true)}
                        onAssignCart2Patient={() => actions.onChangeModalStatus('assign_user_order', true)}
                        onUpdate={actions.onUpdateItem}
                        onShip={() => actions.onChangeModalStatus('shipment_modal', true)}
                        onUpdateShip={() => actions.onChangeModalStatus('update_shipment_modal', true)}
                        onPendingShipment={actions.onChange2PendingShipment}
                        onDeliver={actions.onDeliver}
                    />  
                </Hidden>
                <AddProduct2CartModal 
                    open={modals.add_product_order} 
                    order={order} 
                    onRefresh={actions.onRefreshData}
                    onClose={() => actions.onChangeModalStatus('add_product_order', false)} 
                />
                <Send2ShipmentModal
                    open={modals.shipment_modal} 
                    order={order} 
                    onRefresh={actions.onRefreshSelectedOrder}
                    onClose={() => actions.onChangeModalStatus('shipment_modal', false)} 
                />
                <Send2ShipmentModal
                    open={modals.update_shipment_modal} mode='edit'
                    order={order} 
                    onRefresh={actions.onRefreshSelectedOrder}
                    onClose={() => actions.onChangeModalStatus('update_shipment_modal', false)} 
                />


                
                <DateRangePicker
                    variant="calendar"
                    startDate={dateRangeManager.startDate}
                    endDate={dateRangeManager.endDate}
                    open={modals.date_selector}
                    onChangeStartDate={actions.onChangeStartDate}
                    onChangeEndDate={actions.onChangeEndDate}
                    onClose={() => actions.onChangeModalStatus('date_selector', false)} 

                
                />
                <Grid container alignItems='center' justifyContent='flex-end' sx={{pb:2}} spacing={2}>
                    <Grid item>
                        {docRef.current && <ReactToPrint 
                            trigger={() => <Button>Imprimir orden</Button>}
                            content={() => docRef.current}
                            documentTitle='order'
                            pageStyle='print'
                        />}
                    </Grid>
                    <Grid item>
                        <FileFilterButton
                            //isSelected={!!isSelectedValuePicker}
                            startIcon={<Iconify icon="eva:calendar-fill" />}
                            onClick={() => actions.onChangeModalStatus('date_selector', true)}
                            >
                            {dateRangeManager.shortLabel}
                        </FileFilterButton>
                    </Grid>
                </Grid>
                <Grid container direction='row' spacing={2}  >
                    <Grid item xs={12} 
                        lg={view_order ? 6 : 12} 
                        xl={view_order ? 5 : 12}
                    >

                        <NavigationTab options={TABS} value={order_type} onChange={actions.onChangeSection}/>

                        <Box sx={{marginBottom:2, marginTop:2}}>
                            <TableActionBar onRequestSearch={actions.onRequestSearch}/>
                            
                        </Box>
                        {(system.sending) && <Box sx={{marginBottom:2}}><LinearProgress /></Box> }
                        <Scrollbars
                            autoHeight
                            autoHeightMax='calc(100vh - 380px)'
                        >
                            <Box sx={{paddingRight:2, paddingLeft:1, paddingBottom:3}}>
                                <Stack spacing={2}>
                                    {orders.map((row) => (
                                        <OrderCard
                                            order={row}
                                            key={`order-item-${row?.id_order}`}
                                            row={row}
                                            selected={order?.id_order === row?.id_order}
                                        //selected={selected.includes(row.id)}
                                            onSelect={() => actions.onSelectRow(row)}
                                            onDeleteRow={() => actions.onDeleteRow(row)}
                                            onEditRow={actions.onEditRow}
                                        />
                                    ))}
                                </Stack>
                                
                            </Box>
                        </Scrollbars>
                        
                        <Box sx={{marginTop:4, borderTop:`1px dashed ${grey[300]}`, paddingTop:2}}>
                            <TablePaginationCustom
                                tableFilter={table_config}               
                                total={count}
                                onChangePagination={actions.onChangePagination}
                            />
                        </Box>
                    </Grid>
                    <Hidden lgDown>
                        {view_order && 
                        <Grid item lg={view_order ? 6 : 0} 
                        xl={view_order ? 7 : 0}>
                            <div ref={docRef}>
                            <OrderDetailCard 
                                order={order}
                                onClose={actions.onCloseCartDetails} 
                                loading={system?.order_loading}
                                onRefresh={actions.onRefreshData}
                                onAddProduct={() => actions.onChangeModalStatus('add_product_order', true)}
                                onAssignCart2Patient={() => actions.onChangeModalStatus('assign_user_order', true)}
                                onUpdate={actions.onUpdateItem}
                                onShip={() => actions.onChangeModalStatus('shipment_modal', true)}
                                onUpdateShip={() => actions.onChangeModalStatus('update_shipment_modal', true)}
                                onPendingShipment={actions.onChange2PendingShipment}
                                onDeliver={actions.onDeliver}
                                /> 
                            </div>
                            
                        
                        </Grid> }
                    </Hidden>
                    
                    
                    
                </Grid>
            </div>
        </PageLayout>
     );
}

const useStyles = makeStyles(theme => ({
    card_list:{
        transition:'1s all linear',
        
    },
    card:{
        overflow:'auto'
    }
}))
 
export default OrderListPage;


const CartModal = ({open, onClose, order, loading, 
    onAddProduct, onRefresh, onUpdate, onShip, onUpdateShip, 
    onPendingShipment, onDeliver, onAssignCart2Patient
}) => {
    return(
        <BaseModal open={open} title={`Orden ${order?.id_order}`} onClose={onClose} >
             <OrderDetailCard 
                order={order}
                onClose={onClose} 
                loading={loading}
                onRefresh={onRefresh}
                onAddProduct={onAddProduct}
                onAssignCart2Patient={onAssignCart2Patient}
                onUpdate={onUpdate}
                onShip={onShip}
                onUpdateShip={onUpdateShip}
                onPendingShipment={onPendingShipment}
                onDeliver={onDeliver}
            /> 
        </BaseModal>
    )
    
}